/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None
 *
 * 1 = Admin
 *
 * 2 = Tier2Agent
 *
 * 3 = ClientAgent
 *
 * 4 = IVAgent
 *
 * 5 = PostingAgent
 *
 * 6 = ClientAdmin
 *
 * 7 = CSM
 *
 * 8 = MsUAgent
 */
export enum Role {
  None = 0,
  Admin = 1,
  Tier2Agent = 2,
  ClientAgent = 3,
  IVAgent = 4,
  PostingAgent = 5,
  ClientAdmin = 6,
  CSM = 7,
  MsUAgent = 8
}
