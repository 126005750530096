import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthenticationService } from '@business-level-utility/services/authentication.service';
import { blockedItemsBaseUrl } from '@constants/route-urls/blocked-items.route-urls';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoNAuthGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAuthenticated = this.authenticationService.isUserAuthenticated();
    if (!isAuthenticated) {
      return Promise.resolve(true);
    } else {
      return this.router.parseUrl(blockedItemsBaseUrl); //landing page
    }
  }
}
