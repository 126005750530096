/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None
 *
 * 1 = Blocked
 *
 * 2 = OnDemand
 *
 * 3 = Medical
 *
 * 4 = Posting
 */
export enum TicketType {
  None = 0,
  Blocked = 1,
  OnDemand = 2,
  Medical = 3,
  Posting = 4
}
