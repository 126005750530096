/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV10UsersGet$Json } from '../fn/user/api-v-10-users-get-json';
import { ApiV10UsersGet$Json$Params } from '../fn/user/api-v-10-users-get-json';
import { apiV10UsersGet$Plain } from '../fn/user/api-v-10-users-get-plain';
import { ApiV10UsersGet$Plain$Params } from '../fn/user/api-v-10-users-get-plain';
import { apiV10UsersPost } from '../fn/user/api-v-10-users-post';
import { ApiV10UsersPost$Params } from '../fn/user/api-v-10-users-post';
import { apiV10UsersSearchPost$Json } from '../fn/user/api-v-10-users-search-post-json';
import { ApiV10UsersSearchPost$Json$Params } from '../fn/user/api-v-10-users-search-post-json';
import { apiV10UsersSearchPost$Plain } from '../fn/user/api-v-10-users-search-post-plain';
import { ApiV10UsersSearchPost$Plain$Params } from '../fn/user/api-v-10-users-search-post-plain';
import { apiV10UsersUserIdGet$Json } from '../fn/user/api-v-10-users-user-id-get-json';
import { ApiV10UsersUserIdGet$Json$Params } from '../fn/user/api-v-10-users-user-id-get-json';
import { apiV10UsersUserIdGet$Plain } from '../fn/user/api-v-10-users-user-id-get-plain';
import { ApiV10UsersUserIdGet$Plain$Params } from '../fn/user/api-v-10-users-user-id-get-plain';
import { apiV10UsersUserIdPut$Json } from '../fn/user/api-v-10-users-user-id-put-json';
import { ApiV10UsersUserIdPut$Json$Params } from '../fn/user/api-v-10-users-user-id-put-json';
import { apiV10UsersUserIdPut$Plain } from '../fn/user/api-v-10-users-user-id-put-plain';
import { ApiV10UsersUserIdPut$Plain$Params } from '../fn/user/api-v-10-users-user-id-put-plain';
import { UserDetailBaseResponse } from '../models/user-detail-base-response';
import { UserDetailResponse } from '../models/user-detail-response';
import { UserQueryResonse } from '../models/user-query-resonse';
import { UserUpdateResponse } from '../models/user-update-response';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV10UsersGet()` */
  static readonly ApiV10UsersGetPath = '/api/v1.0/users';

  /**
   * get all users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10UsersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10UsersGet$Plain$Response(params?: ApiV10UsersGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDetailBaseResponse>>> {
    return apiV10UsersGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get all users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10UsersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10UsersGet$Plain(params?: ApiV10UsersGet$Plain$Params, context?: HttpContext): Observable<Array<UserDetailBaseResponse>> {
    return this.apiV10UsersGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDetailBaseResponse>>): Array<UserDetailBaseResponse> => r.body)
    );
  }

  /**
   * get all users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10UsersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10UsersGet$Json$Response(params?: ApiV10UsersGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDetailBaseResponse>>> {
    return apiV10UsersGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get all users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10UsersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10UsersGet$Json(params?: ApiV10UsersGet$Json$Params, context?: HttpContext): Observable<Array<UserDetailBaseResponse>> {
    return this.apiV10UsersGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDetailBaseResponse>>): Array<UserDetailBaseResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10UsersPost()` */
  static readonly ApiV10UsersPostPath = '/api/v1.0/users';

  /**
   * create user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10UsersPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10UsersPost$Response(params: ApiV10UsersPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV10UsersPost(this.http, this.rootUrl, params, context);
  }

  /**
   * create user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10UsersPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10UsersPost(params: ApiV10UsersPost$Params, context?: HttpContext): Observable<void> {
    return this.apiV10UsersPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV10UsersUserIdGet()` */
  static readonly ApiV10UsersUserIdGetPath = '/api/v1.0/users/{userId}';

  /**
   * get user details by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10UsersUserIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10UsersUserIdGet$Plain$Response(params: ApiV10UsersUserIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDetailResponse>> {
    return apiV10UsersUserIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get user details by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10UsersUserIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10UsersUserIdGet$Plain(params: ApiV10UsersUserIdGet$Plain$Params, context?: HttpContext): Observable<UserDetailResponse> {
    return this.apiV10UsersUserIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetailResponse>): UserDetailResponse => r.body)
    );
  }

  /**
   * get user details by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10UsersUserIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10UsersUserIdGet$Json$Response(params: ApiV10UsersUserIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDetailResponse>> {
    return apiV10UsersUserIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get user details by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10UsersUserIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10UsersUserIdGet$Json(params: ApiV10UsersUserIdGet$Json$Params, context?: HttpContext): Observable<UserDetailResponse> {
    return this.apiV10UsersUserIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetailResponse>): UserDetailResponse => r.body)
    );
  }

  /** Path part for operation `apiV10UsersUserIdPut()` */
  static readonly ApiV10UsersUserIdPutPath = '/api/v1.0/users/{userId}';

  /**
   * update user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10UsersUserIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10UsersUserIdPut$Plain$Response(params: ApiV10UsersUserIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUpdateResponse>> {
    return apiV10UsersUserIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * update user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10UsersUserIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10UsersUserIdPut$Plain(params: ApiV10UsersUserIdPut$Plain$Params, context?: HttpContext): Observable<UserUpdateResponse> {
    return this.apiV10UsersUserIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUpdateResponse>): UserUpdateResponse => r.body)
    );
  }

  /**
   * update user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10UsersUserIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10UsersUserIdPut$Json$Response(params: ApiV10UsersUserIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUpdateResponse>> {
    return apiV10UsersUserIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * update user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10UsersUserIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10UsersUserIdPut$Json(params: ApiV10UsersUserIdPut$Json$Params, context?: HttpContext): Observable<UserUpdateResponse> {
    return this.apiV10UsersUserIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUpdateResponse>): UserUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10UsersSearchPost()` */
  static readonly ApiV10UsersSearchPostPath = '/api/v1.0/users/search';

  /**
   * users search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10UsersSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10UsersSearchPost$Plain$Response(params: ApiV10UsersSearchPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserQueryResonse>>> {
    return apiV10UsersSearchPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * users search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10UsersSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10UsersSearchPost$Plain(params: ApiV10UsersSearchPost$Plain$Params, context?: HttpContext): Observable<Array<UserQueryResonse>> {
    return this.apiV10UsersSearchPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserQueryResonse>>): Array<UserQueryResonse> => r.body)
    );
  }

  /**
   * users search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10UsersSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10UsersSearchPost$Json$Response(params: ApiV10UsersSearchPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserQueryResonse>>> {
    return apiV10UsersSearchPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * users search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10UsersSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10UsersSearchPost$Json(params: ApiV10UsersSearchPost$Json$Params, context?: HttpContext): Observable<Array<UserQueryResonse>> {
    return this.apiV10UsersSearchPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserQueryResonse>>): Array<UserQueryResonse> => r.body)
    );
  }

}
