/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV10PostingTicketsExportPost$Json } from '../fn/posting-ticket/api-v-10-posting-tickets-export-post-json';
import { ApiV10PostingTicketsExportPost$Json$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-export-post-json';
import { apiV10PostingTicketsExportPost$Plain } from '../fn/posting-ticket/api-v-10-posting-tickets-export-post-plain';
import { ApiV10PostingTicketsExportPost$Plain$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-export-post-plain';
import { apiV10PostingTicketsMailSentTicketIdPost } from '../fn/posting-ticket/api-v-10-posting-tickets-mail-sent-ticket-id-post';
import { ApiV10PostingTicketsMailSentTicketIdPost$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-mail-sent-ticket-id-post';
import { apiV10PostingTicketsMailTemplateGet$Json } from '../fn/posting-ticket/api-v-10-posting-tickets-mail-template-get-json';
import { ApiV10PostingTicketsMailTemplateGet$Json$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-mail-template-get-json';
import { apiV10PostingTicketsMailTemplateGet$Plain } from '../fn/posting-ticket/api-v-10-posting-tickets-mail-template-get-plain';
import { ApiV10PostingTicketsMailTemplateGet$Plain$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-mail-template-get-plain';
import { apiV10PostingTicketsPost$Json } from '../fn/posting-ticket/api-v-10-posting-tickets-post-json';
import { ApiV10PostingTicketsPost$Json$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-post-json';
import { apiV10PostingTicketsPost$Plain } from '../fn/posting-ticket/api-v-10-posting-tickets-post-plain';
import { ApiV10PostingTicketsPost$Plain$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-post-plain';
import { apiV10PostingTicketsSearchPost$Json } from '../fn/posting-ticket/api-v-10-posting-tickets-search-post-json';
import { ApiV10PostingTicketsSearchPost$Json$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-search-post-json';
import { apiV10PostingTicketsSearchPost$Plain } from '../fn/posting-ticket/api-v-10-posting-tickets-search-post-plain';
import { ApiV10PostingTicketsSearchPost$Plain$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-search-post-plain';
import { apiV10PostingTicketsTicketIdGet$Json } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-get-json';
import { ApiV10PostingTicketsTicketIdGet$Json$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-get-json';
import { apiV10PostingTicketsTicketIdGet$Plain } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-get-plain';
import { ApiV10PostingTicketsTicketIdGet$Plain$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-get-plain';
import { apiV10PostingTicketsTicketIdMailContentGet$Json } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-mail-content-get-json';
import { ApiV10PostingTicketsTicketIdMailContentGet$Json$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-mail-content-get-json';
import { apiV10PostingTicketsTicketIdMailContentGet$Plain } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-mail-content-get-plain';
import { ApiV10PostingTicketsTicketIdMailContentGet$Plain$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-mail-content-get-plain';
import { apiV10PostingTicketsTicketIdPut$Json } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-put-json';
import { ApiV10PostingTicketsTicketIdPut$Json$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-put-json';
import { apiV10PostingTicketsTicketIdPut$Plain } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-put-plain';
import { ApiV10PostingTicketsTicketIdPut$Plain$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-put-plain';
import { apiV10PostingTicketsTicketIdRevisionsGet$Json } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-revisions-get-json';
import { ApiV10PostingTicketsTicketIdRevisionsGet$Json$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-revisions-get-json';
import { apiV10PostingTicketsTicketIdRevisionsGet$Plain } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-revisions-get-plain';
import { ApiV10PostingTicketsTicketIdRevisionsGet$Plain$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-revisions-get-plain';
import { apiV10PostingTicketsTicketIdStatusPatch$Json } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-status-patch-json';
import { ApiV10PostingTicketsTicketIdStatusPatch$Json$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-status-patch-json';
import { apiV10PostingTicketsTicketIdStatusPatch$Plain } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-status-patch-plain';
import { ApiV10PostingTicketsTicketIdStatusPatch$Plain$Params } from '../fn/posting-ticket/api-v-10-posting-tickets-ticket-id-status-patch-plain';
import { PostingTicketEmail } from '../models/posting-ticket-email';
import { PostingTicketQueryResponse } from '../models/posting-ticket-query-response';
import { PostingTicketResponse } from '../models/posting-ticket-response';
import { TicketHistoryResponse } from '../models/ticket-history-response';
import { TicketResponseBase } from '../models/ticket-response-base';
import { TicketUpdateResponse } from '../models/ticket-update-response';

@Injectable({ providedIn: 'root' })
export class PostingTicketService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV10PostingTicketsPost()` */
  static readonly ApiV10PostingTicketsPostPath = '/api/v1.0/posting-tickets';

  /**
   * PaymentPosting ticket creation api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsPost$Plain$Response(params: ApiV10PostingTicketsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponseBase>> {
    return apiV10PostingTicketsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket creation api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsPost$Plain(params: ApiV10PostingTicketsPost$Plain$Params, context?: HttpContext): Observable<TicketResponseBase> {
    return this.apiV10PostingTicketsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponseBase>): TicketResponseBase => r.body)
    );
  }

  /**
   * PaymentPosting ticket creation api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsPost$Json$Response(params: ApiV10PostingTicketsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponseBase>> {
    return apiV10PostingTicketsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket creation api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsPost$Json(params: ApiV10PostingTicketsPost$Json$Params, context?: HttpContext): Observable<TicketResponseBase> {
    return this.apiV10PostingTicketsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponseBase>): TicketResponseBase => r.body)
    );
  }

  /** Path part for operation `apiV10PostingTicketsTicketIdGet()` */
  static readonly ApiV10PostingTicketsTicketIdGetPath = '/api/v1.0/posting-tickets/{ticketId}';

  /**
   * PaymentPosting ticket get by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsTicketIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdGet$Plain$Response(params: ApiV10PostingTicketsTicketIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PostingTicketResponse>> {
    return apiV10PostingTicketsTicketIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket get by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsTicketIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdGet$Plain(params: ApiV10PostingTicketsTicketIdGet$Plain$Params, context?: HttpContext): Observable<PostingTicketResponse> {
    return this.apiV10PostingTicketsTicketIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostingTicketResponse>): PostingTicketResponse => r.body)
    );
  }

  /**
   * PaymentPosting ticket get by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsTicketIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdGet$Json$Response(params: ApiV10PostingTicketsTicketIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PostingTicketResponse>> {
    return apiV10PostingTicketsTicketIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket get by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsTicketIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdGet$Json(params: ApiV10PostingTicketsTicketIdGet$Json$Params, context?: HttpContext): Observable<PostingTicketResponse> {
    return this.apiV10PostingTicketsTicketIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostingTicketResponse>): PostingTicketResponse => r.body)
    );
  }

  /** Path part for operation `apiV10PostingTicketsTicketIdPut()` */
  static readonly ApiV10PostingTicketsTicketIdPutPath = '/api/v1.0/posting-tickets/{ticketId}';

  /**
   * PaymentPosting ticket update by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsTicketIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsTicketIdPut$Plain$Response(params: ApiV10PostingTicketsTicketIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PostingTicketResponse>> {
    return apiV10PostingTicketsTicketIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket update by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsTicketIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsTicketIdPut$Plain(params: ApiV10PostingTicketsTicketIdPut$Plain$Params, context?: HttpContext): Observable<PostingTicketResponse> {
    return this.apiV10PostingTicketsTicketIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostingTicketResponse>): PostingTicketResponse => r.body)
    );
  }

  /**
   * PaymentPosting ticket update by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsTicketIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsTicketIdPut$Json$Response(params: ApiV10PostingTicketsTicketIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PostingTicketResponse>> {
    return apiV10PostingTicketsTicketIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket update by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsTicketIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsTicketIdPut$Json(params: ApiV10PostingTicketsTicketIdPut$Json$Params, context?: HttpContext): Observable<PostingTicketResponse> {
    return this.apiV10PostingTicketsTicketIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostingTicketResponse>): PostingTicketResponse => r.body)
    );
  }

  /** Path part for operation `apiV10PostingTicketsTicketIdStatusPatch()` */
  static readonly ApiV10PostingTicketsTicketIdStatusPatchPath = '/api/v1.0/posting-tickets/{ticketId}/status';

  /**
   * PaymentPosting ticket status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsTicketIdStatusPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsTicketIdStatusPatch$Plain$Response(params: ApiV10PostingTicketsTicketIdStatusPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10PostingTicketsTicketIdStatusPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsTicketIdStatusPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsTicketIdStatusPatch$Plain(params: ApiV10PostingTicketsTicketIdStatusPatch$Plain$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10PostingTicketsTicketIdStatusPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /**
   * PaymentPosting ticket status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsTicketIdStatusPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsTicketIdStatusPatch$Json$Response(params: ApiV10PostingTicketsTicketIdStatusPatch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10PostingTicketsTicketIdStatusPatch$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsTicketIdStatusPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsTicketIdStatusPatch$Json(params: ApiV10PostingTicketsTicketIdStatusPatch$Json$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10PostingTicketsTicketIdStatusPatch$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10PostingTicketsSearchPost()` */
  static readonly ApiV10PostingTicketsSearchPostPath = '/api/v1.0/posting-tickets/search';

  /**
   * PaymentPosting ticket search api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsSearchPost$Plain$Response(params: ApiV10PostingTicketsSearchPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PostingTicketQueryResponse>>> {
    return apiV10PostingTicketsSearchPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket search api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsSearchPost$Plain(params: ApiV10PostingTicketsSearchPost$Plain$Params, context?: HttpContext): Observable<Array<PostingTicketQueryResponse>> {
    return this.apiV10PostingTicketsSearchPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PostingTicketQueryResponse>>): Array<PostingTicketQueryResponse> => r.body)
    );
  }

  /**
   * PaymentPosting ticket search api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsSearchPost$Json$Response(params: ApiV10PostingTicketsSearchPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PostingTicketQueryResponse>>> {
    return apiV10PostingTicketsSearchPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket search api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsSearchPost$Json(params: ApiV10PostingTicketsSearchPost$Json$Params, context?: HttpContext): Observable<Array<PostingTicketQueryResponse>> {
    return this.apiV10PostingTicketsSearchPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PostingTicketQueryResponse>>): Array<PostingTicketQueryResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10PostingTicketsExportPost()` */
  static readonly ApiV10PostingTicketsExportPostPath = '/api/v1.0/posting-tickets/export';

  /**
   * export ticket.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsExportPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsExportPost$Plain$Response(params: ApiV10PostingTicketsExportPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<File>> {
    return apiV10PostingTicketsExportPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsExportPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsExportPost$Plain(params: ApiV10PostingTicketsExportPost$Plain$Params, context?: HttpContext): Observable<File> {
    return this.apiV10PostingTicketsExportPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<File>): File => r.body)
    );
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsExportPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsExportPost$Json$Response(params: ApiV10PostingTicketsExportPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<File>> {
    return apiV10PostingTicketsExportPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsExportPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10PostingTicketsExportPost$Json(params: ApiV10PostingTicketsExportPost$Json$Params, context?: HttpContext): Observable<File> {
    return this.apiV10PostingTicketsExportPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<File>): File => r.body)
    );
  }

  /** Path part for operation `apiV10PostingTicketsTicketIdRevisionsGet()` */
  static readonly ApiV10PostingTicketsTicketIdRevisionsGetPath = '/api/v1.0/posting-tickets/{ticketId}/revisions';

  /**
   * PaymentPosting ticket history get by ticket id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsTicketIdRevisionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdRevisionsGet$Plain$Response(params: ApiV10PostingTicketsTicketIdRevisionsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketHistoryResponse>>> {
    return apiV10PostingTicketsTicketIdRevisionsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket history get by ticket id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsTicketIdRevisionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdRevisionsGet$Plain(params: ApiV10PostingTicketsTicketIdRevisionsGet$Plain$Params, context?: HttpContext): Observable<Array<TicketHistoryResponse>> {
    return this.apiV10PostingTicketsTicketIdRevisionsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketHistoryResponse>>): Array<TicketHistoryResponse> => r.body)
    );
  }

  /**
   * PaymentPosting ticket history get by ticket id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsTicketIdRevisionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdRevisionsGet$Json$Response(params: ApiV10PostingTicketsTicketIdRevisionsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketHistoryResponse>>> {
    return apiV10PostingTicketsTicketIdRevisionsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * PaymentPosting ticket history get by ticket id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsTicketIdRevisionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdRevisionsGet$Json(params: ApiV10PostingTicketsTicketIdRevisionsGet$Json$Params, context?: HttpContext): Observable<Array<TicketHistoryResponse>> {
    return this.apiV10PostingTicketsTicketIdRevisionsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketHistoryResponse>>): Array<TicketHistoryResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10PostingTicketsMailTemplateGet()` */
  static readonly ApiV10PostingTicketsMailTemplateGetPath = '/api/v1.0/posting-tickets/mail-template';

  /**
   * Get PaymentPosting Email To be Sent.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsMailTemplateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsMailTemplateGet$Plain$Response(params?: ApiV10PostingTicketsMailTemplateGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return apiV10PostingTicketsMailTemplateGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PaymentPosting Email To be Sent.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsMailTemplateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsMailTemplateGet$Plain(params?: ApiV10PostingTicketsMailTemplateGet$Plain$Params, context?: HttpContext): Observable<string> {
    return this.apiV10PostingTicketsMailTemplateGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Get PaymentPosting Email To be Sent.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsMailTemplateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsMailTemplateGet$Json$Response(params?: ApiV10PostingTicketsMailTemplateGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return apiV10PostingTicketsMailTemplateGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PaymentPosting Email To be Sent.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsMailTemplateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsMailTemplateGet$Json(params?: ApiV10PostingTicketsMailTemplateGet$Json$Params, context?: HttpContext): Observable<string> {
    return this.apiV10PostingTicketsMailTemplateGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiV10PostingTicketsTicketIdMailContentGet()` */
  static readonly ApiV10PostingTicketsTicketIdMailContentGetPath = '/api/v1.0/posting-tickets/{ticketId}/mail-content';

  /**
   * Get PaymentPosting Email To be Sent.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsTicketIdMailContentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdMailContentGet$Plain$Response(params: ApiV10PostingTicketsTicketIdMailContentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PostingTicketEmail>> {
    return apiV10PostingTicketsTicketIdMailContentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PaymentPosting Email To be Sent.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsTicketIdMailContentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdMailContentGet$Plain(params: ApiV10PostingTicketsTicketIdMailContentGet$Plain$Params, context?: HttpContext): Observable<PostingTicketEmail> {
    return this.apiV10PostingTicketsTicketIdMailContentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostingTicketEmail>): PostingTicketEmail => r.body)
    );
  }

  /**
   * Get PaymentPosting Email To be Sent.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsTicketIdMailContentGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdMailContentGet$Json$Response(params: ApiV10PostingTicketsTicketIdMailContentGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PostingTicketEmail>> {
    return apiV10PostingTicketsTicketIdMailContentGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PaymentPosting Email To be Sent.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsTicketIdMailContentGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsTicketIdMailContentGet$Json(params: ApiV10PostingTicketsTicketIdMailContentGet$Json$Params, context?: HttpContext): Observable<PostingTicketEmail> {
    return this.apiV10PostingTicketsTicketIdMailContentGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostingTicketEmail>): PostingTicketEmail => r.body)
    );
  }

  /** Path part for operation `apiV10PostingTicketsMailSentTicketIdPost()` */
  static readonly ApiV10PostingTicketsMailSentTicketIdPostPath = '/api/v1.0/posting-tickets/mail-sent/{ticketId}';

  /**
   * Send PaymentPosting Email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10PostingTicketsMailSentTicketIdPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsMailSentTicketIdPost$Response(params: ApiV10PostingTicketsMailSentTicketIdPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV10PostingTicketsMailSentTicketIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Send PaymentPosting Email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10PostingTicketsMailSentTicketIdPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10PostingTicketsMailSentTicketIdPost(params: ApiV10PostingTicketsMailSentTicketIdPost$Params, context?: HttpContext): Observable<void> {
    return this.apiV10PostingTicketsMailSentTicketIdPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
