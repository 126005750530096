export class AppConfig {
  serverUrl: string;
  systemLocale?: string;
  appInsightsKey: string;
  version: string;
  systemUserId?: number;

  constructor() {
    this.systemLocale = 'en-US';
    this.systemUserId = 9999;
    this.appInsightsKey = '';
    this.version = '';
    this.serverUrl = 'https://localhost:44356';
  }
}
