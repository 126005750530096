/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None
 *
 * 1 = MedicalOnHold
 *
 * 2 = MedicalInProgress
 *
 * 4 = MedicalCompleted
 *
 * 8 = VerificationRequestOnHold
 *
 * 16 = VerificationRequestInProgress
 *
 * 32 = VerificationRequestCompleted
 *
 * 64 = BlockedItemAction
 *
 * 128 = BlockedItemNoAction
 *
 * 256 = BlockedItemInProgress
 *
 * 512 = BlockedItemOnHold
 *
 * 1024 = BlockedItemCompleted
 *
 * 2048 = PostingItemTotalMismatch
 *
 * 4096 = PostingItemPending
 *
 * 8192 = PostingItemCompleted
 */
export enum EmailSubscriptionType {
  None = 0,
  MedicalOnHold = 1,
  MedicalInProgress = 2,
  MedicalCompleted = 4,
  VerificationRequestOnHold = 8,
  VerificationRequestInProgress = 16,
  VerificationRequestCompleted = 32,
  BlockedItemAction = 64,
  BlockedItemNoAction = 128,
  BlockedItemInProgress = 256,
  BlockedItemOnHold = 512,
  BlockedItemCompleted = 1024,
  PostingItemTotalMismatch = 2048,
  PostingItemPending = 4096,
  PostingItemCompleted = 8192
}
