/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV10TicketAttachmentsAttachmentIdDelete } from '../fn/ticket-attachment/api-v-10-ticket-attachments-attachment-id-delete';
import { ApiV10TicketAttachmentsAttachmentIdDelete$Params } from '../fn/ticket-attachment/api-v-10-ticket-attachments-attachment-id-delete';
import { apiV10TicketAttachmentsAttachmentIdUriGet$Json } from '../fn/ticket-attachment/api-v-10-ticket-attachments-attachment-id-uri-get-json';
import { ApiV10TicketAttachmentsAttachmentIdUriGet$Json$Params } from '../fn/ticket-attachment/api-v-10-ticket-attachments-attachment-id-uri-get-json';
import { apiV10TicketAttachmentsAttachmentIdUriGet$Plain } from '../fn/ticket-attachment/api-v-10-ticket-attachments-attachment-id-uri-get-plain';
import { ApiV10TicketAttachmentsAttachmentIdUriGet$Plain$Params } from '../fn/ticket-attachment/api-v-10-ticket-attachments-attachment-id-uri-get-plain';
import { apiV10TicketAttachmentsGet$Json } from '../fn/ticket-attachment/api-v-10-ticket-attachments-get-json';
import { ApiV10TicketAttachmentsGet$Json$Params } from '../fn/ticket-attachment/api-v-10-ticket-attachments-get-json';
import { apiV10TicketAttachmentsGet$Plain } from '../fn/ticket-attachment/api-v-10-ticket-attachments-get-plain';
import { ApiV10TicketAttachmentsGet$Plain$Params } from '../fn/ticket-attachment/api-v-10-ticket-attachments-get-plain';
import { apiV10TicketAttachmentsPost$Json } from '../fn/ticket-attachment/api-v-10-ticket-attachments-post-json';
import { ApiV10TicketAttachmentsPost$Json$Params } from '../fn/ticket-attachment/api-v-10-ticket-attachments-post-json';
import { apiV10TicketAttachmentsPost$Plain } from '../fn/ticket-attachment/api-v-10-ticket-attachments-post-plain';
import { ApiV10TicketAttachmentsPost$Plain$Params } from '../fn/ticket-attachment/api-v-10-ticket-attachments-post-plain';
import { apiV10TicketAttachmentsSasTokenPost$Json } from '../fn/ticket-attachment/api-v-10-ticket-attachments-sas-token-post-json';
import { ApiV10TicketAttachmentsSasTokenPost$Json$Params } from '../fn/ticket-attachment/api-v-10-ticket-attachments-sas-token-post-json';
import { apiV10TicketAttachmentsSasTokenPost$Plain } from '../fn/ticket-attachment/api-v-10-ticket-attachments-sas-token-post-plain';
import { ApiV10TicketAttachmentsSasTokenPost$Plain$Params } from '../fn/ticket-attachment/api-v-10-ticket-attachments-sas-token-post-plain';
import { AttachmentResponse } from '../models/attachment-response';
import { BlobSas } from '../models/blob-sas';

@Injectable({ providedIn: 'root' })
export class TicketAttachmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV10TicketAttachmentsSasTokenPost()` */
  static readonly ApiV10TicketAttachmentsSasTokenPostPath = '/api/v1.0/ticket-attachments/sas-token';

  /**
   * generate sas token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketAttachmentsSasTokenPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketAttachmentsSasTokenPost$Plain$Response(params: ApiV10TicketAttachmentsSasTokenPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BlobSas>> {
    return apiV10TicketAttachmentsSasTokenPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * generate sas token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketAttachmentsSasTokenPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketAttachmentsSasTokenPost$Plain(params: ApiV10TicketAttachmentsSasTokenPost$Plain$Params, context?: HttpContext): Observable<BlobSas> {
    return this.apiV10TicketAttachmentsSasTokenPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlobSas>): BlobSas => r.body)
    );
  }

  /**
   * generate sas token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketAttachmentsSasTokenPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketAttachmentsSasTokenPost$Json$Response(params: ApiV10TicketAttachmentsSasTokenPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BlobSas>> {
    return apiV10TicketAttachmentsSasTokenPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * generate sas token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketAttachmentsSasTokenPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketAttachmentsSasTokenPost$Json(params: ApiV10TicketAttachmentsSasTokenPost$Json$Params, context?: HttpContext): Observable<BlobSas> {
    return this.apiV10TicketAttachmentsSasTokenPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlobSas>): BlobSas => r.body)
    );
  }

  /** Path part for operation `apiV10TicketAttachmentsGet()` */
  static readonly ApiV10TicketAttachmentsGetPath = '/api/v1.0/ticket-attachments';

  /**
   * get all attachments by ticket id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketAttachmentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketAttachmentsGet$Plain$Response(params: ApiV10TicketAttachmentsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AttachmentResponse>>> {
    return apiV10TicketAttachmentsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get all attachments by ticket id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketAttachmentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketAttachmentsGet$Plain(params: ApiV10TicketAttachmentsGet$Plain$Params, context?: HttpContext): Observable<Array<AttachmentResponse>> {
    return this.apiV10TicketAttachmentsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttachmentResponse>>): Array<AttachmentResponse> => r.body)
    );
  }

  /**
   * get all attachments by ticket id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketAttachmentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketAttachmentsGet$Json$Response(params: ApiV10TicketAttachmentsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AttachmentResponse>>> {
    return apiV10TicketAttachmentsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get all attachments by ticket id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketAttachmentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketAttachmentsGet$Json(params: ApiV10TicketAttachmentsGet$Json$Params, context?: HttpContext): Observable<Array<AttachmentResponse>> {
    return this.apiV10TicketAttachmentsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttachmentResponse>>): Array<AttachmentResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10TicketAttachmentsPost()` */
  static readonly ApiV10TicketAttachmentsPostPath = '/api/v1.0/ticket-attachments';

  /**
   * add new attachment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketAttachmentsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketAttachmentsPost$Plain$Response(params: ApiV10TicketAttachmentsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentResponse>> {
    return apiV10TicketAttachmentsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * add new attachment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketAttachmentsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketAttachmentsPost$Plain(params: ApiV10TicketAttachmentsPost$Plain$Params, context?: HttpContext): Observable<AttachmentResponse> {
    return this.apiV10TicketAttachmentsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentResponse>): AttachmentResponse => r.body)
    );
  }

  /**
   * add new attachment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketAttachmentsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketAttachmentsPost$Json$Response(params: ApiV10TicketAttachmentsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentResponse>> {
    return apiV10TicketAttachmentsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * add new attachment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketAttachmentsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketAttachmentsPost$Json(params: ApiV10TicketAttachmentsPost$Json$Params, context?: HttpContext): Observable<AttachmentResponse> {
    return this.apiV10TicketAttachmentsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentResponse>): AttachmentResponse => r.body)
    );
  }

  /** Path part for operation `apiV10TicketAttachmentsAttachmentIdUriGet()` */
  static readonly ApiV10TicketAttachmentsAttachmentIdUriGetPath = '/api/v1.0/ticket-attachments/{attachmentId}/uri';

  /**
   * get attachment blob uri.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketAttachmentsAttachmentIdUriGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketAttachmentsAttachmentIdUriGet$Plain$Response(params: ApiV10TicketAttachmentsAttachmentIdUriGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return apiV10TicketAttachmentsAttachmentIdUriGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get attachment blob uri.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketAttachmentsAttachmentIdUriGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketAttachmentsAttachmentIdUriGet$Plain(params: ApiV10TicketAttachmentsAttachmentIdUriGet$Plain$Params, context?: HttpContext): Observable<string> {
    return this.apiV10TicketAttachmentsAttachmentIdUriGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * get attachment blob uri.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketAttachmentsAttachmentIdUriGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketAttachmentsAttachmentIdUriGet$Json$Response(params: ApiV10TicketAttachmentsAttachmentIdUriGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return apiV10TicketAttachmentsAttachmentIdUriGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get attachment blob uri.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketAttachmentsAttachmentIdUriGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketAttachmentsAttachmentIdUriGet$Json(params: ApiV10TicketAttachmentsAttachmentIdUriGet$Json$Params, context?: HttpContext): Observable<string> {
    return this.apiV10TicketAttachmentsAttachmentIdUriGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiV10TicketAttachmentsAttachmentIdDelete()` */
  static readonly ApiV10TicketAttachmentsAttachmentIdDeletePath = '/api/v1.0/ticket-attachments/{attachmentId}';

  /**
   * delete attachment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketAttachmentsAttachmentIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketAttachmentsAttachmentIdDelete$Response(params: ApiV10TicketAttachmentsAttachmentIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV10TicketAttachmentsAttachmentIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * delete attachment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketAttachmentsAttachmentIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketAttachmentsAttachmentIdDelete(params: ApiV10TicketAttachmentsAttachmentIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV10TicketAttachmentsAttachmentIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
