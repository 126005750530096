/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None
 *
 * 1 = Open
 *
 * 2 = OpenPracticeActionRequired
 *
 * 3 = AgentActionRequired
 *
 * 4 = InProgress
 *
 * 5 = AgentActionWIP
 *
 * 6 = OnHold
 *
 * 7 = ItemsPending
 *
 * 8 = TotalMismatch
 *
 * 9 = Rejected
 *
 * 10 = ClosedByPractice
 *
 * 11 = ClosedByAgent
 *
 * 12 = Completed
 *
 * 13 = Draft
 */
export enum TicketStatus {
  None = 0,
  Open = 1,
  OpenPracticeActionRequired = 2,
  AgentActionRequired = 3,
  InProgress = 4,
  AgentActionWIP = 5,
  OnHold = 6,
  ItemsPending = 7,
  TotalMismatch = 8,
  Rejected = 9,
  ClosedByPractice = 10,
  ClosedByAgent = 11,
  Completed = 12,
  Draft = 13
}
