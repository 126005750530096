import { HttpHandlerFn, HttpRequest } from '@angular/common/http';

export function AuthorizationInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) {
  const accessToken = localStorage.getItem('userToken');
  if (req.url.includes('blob.core.windows.net')) {
    return next(req);
  }
  return next(
    req.clone({ setHeaders: { authorization: `Bearer ${accessToken}` } }),
  );
}
