/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None
 *
 * 1 = Eastern
 *
 * 2 = Central
 *
 * 3 = Mountain
 *
 * 4 = Pacific
 *
 * 5 = Hawaiian
 */
export enum TimeZoneType {
  None = 0,
  Eastern = 1,
  Central = 2,
  Mountain = 3,
  Pacific = 4,
  Hawaiian = 5
}
