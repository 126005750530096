/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV10AuthenticationLinkValidationPost$Json } from '../fn/auth/api-v-10-authentication-link-validation-post-json';
import { ApiV10AuthenticationLinkValidationPost$Json$Params } from '../fn/auth/api-v-10-authentication-link-validation-post-json';
import { apiV10AuthenticationLinkValidationPost$Plain } from '../fn/auth/api-v-10-authentication-link-validation-post-plain';
import { ApiV10AuthenticationLinkValidationPost$Plain$Params } from '../fn/auth/api-v-10-authentication-link-validation-post-plain';
import { apiV10AuthenticationLoginPost$Json } from '../fn/auth/api-v-10-authentication-login-post-json';
import { ApiV10AuthenticationLoginPost$Json$Params } from '../fn/auth/api-v-10-authentication-login-post-json';
import { apiV10AuthenticationLoginPost$Plain } from '../fn/auth/api-v-10-authentication-login-post-plain';
import { ApiV10AuthenticationLoginPost$Plain$Params } from '../fn/auth/api-v-10-authentication-login-post-plain';
import { apiV10AuthenticationRefreshPost$Json } from '../fn/auth/api-v-10-authentication-refresh-post-json';
import { ApiV10AuthenticationRefreshPost$Json$Params } from '../fn/auth/api-v-10-authentication-refresh-post-json';
import { apiV10AuthenticationRefreshPost$Plain } from '../fn/auth/api-v-10-authentication-refresh-post-plain';
import { ApiV10AuthenticationRefreshPost$Plain$Params } from '../fn/auth/api-v-10-authentication-refresh-post-plain';
import { apiV10AuthenticationResendOtpPost$Json } from '../fn/auth/api-v-10-authentication-resend-otp-post-json';
import { ApiV10AuthenticationResendOtpPost$Json$Params } from '../fn/auth/api-v-10-authentication-resend-otp-post-json';
import { apiV10AuthenticationResendOtpPost$Plain } from '../fn/auth/api-v-10-authentication-resend-otp-post-plain';
import { ApiV10AuthenticationResendOtpPost$Plain$Params } from '../fn/auth/api-v-10-authentication-resend-otp-post-plain';
import { apiV10AuthenticationResetPasswordPost } from '../fn/auth/api-v-10-authentication-reset-password-post';
import { ApiV10AuthenticationResetPasswordPost$Params } from '../fn/auth/api-v-10-authentication-reset-password-post';
import { apiV10AuthenticationSendMailPost } from '../fn/auth/api-v-10-authentication-send-mail-post';
import { ApiV10AuthenticationSendMailPost$Params } from '../fn/auth/api-v-10-authentication-send-mail-post';
import { apiV10AuthenticationVerifyOtpPost$Json } from '../fn/auth/api-v-10-authentication-verify-otp-post-json';
import { ApiV10AuthenticationVerifyOtpPost$Json$Params } from '../fn/auth/api-v-10-authentication-verify-otp-post-json';
import { apiV10AuthenticationVerifyOtpPost$Plain } from '../fn/auth/api-v-10-authentication-verify-otp-post-plain';
import { ApiV10AuthenticationVerifyOtpPost$Plain$Params } from '../fn/auth/api-v-10-authentication-verify-otp-post-plain';
import { Tokens } from '../models/tokens';
import { UserResponse } from '../models/user-response';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV10AuthenticationLoginPost()` */
  static readonly ApiV10AuthenticationLoginPostPath = '/api/v1.0/authentication/login';

  /**
   * login api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationLoginPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AuthenticationLoginPost$Plain$Response(params: ApiV10AuthenticationLoginPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return apiV10AuthenticationLoginPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * login api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationLoginPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AuthenticationLoginPost$Plain(params: ApiV10AuthenticationLoginPost$Plain$Params, context?: HttpContext): Observable<UserResponse> {
    return this.apiV10AuthenticationLoginPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /**
   * login api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationLoginPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AuthenticationLoginPost$Json$Response(params: ApiV10AuthenticationLoginPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return apiV10AuthenticationLoginPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * login api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationLoginPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AuthenticationLoginPost$Json(params: ApiV10AuthenticationLoginPost$Json$Params, context?: HttpContext): Observable<UserResponse> {
    return this.apiV10AuthenticationLoginPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `apiV10AuthenticationVerifyOtpPost()` */
  static readonly ApiV10AuthenticationVerifyOtpPostPath = '/api/v1.0/authentication/verify-otp';

  /**
   * verify otp api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationVerifyOtpPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AuthenticationVerifyOtpPost$Plain$Response(params: ApiV10AuthenticationVerifyOtpPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return apiV10AuthenticationVerifyOtpPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * verify otp api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationVerifyOtpPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AuthenticationVerifyOtpPost$Plain(params: ApiV10AuthenticationVerifyOtpPost$Plain$Params, context?: HttpContext): Observable<UserResponse> {
    return this.apiV10AuthenticationVerifyOtpPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /**
   * verify otp api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationVerifyOtpPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AuthenticationVerifyOtpPost$Json$Response(params: ApiV10AuthenticationVerifyOtpPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return apiV10AuthenticationVerifyOtpPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * verify otp api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationVerifyOtpPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AuthenticationVerifyOtpPost$Json(params: ApiV10AuthenticationVerifyOtpPost$Json$Params, context?: HttpContext): Observable<UserResponse> {
    return this.apiV10AuthenticationVerifyOtpPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `apiV10AuthenticationResendOtpPost()` */
  static readonly ApiV10AuthenticationResendOtpPostPath = '/api/v1.0/authentication/resend-otp';

  /**
   * resend otp api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationResendOtpPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationResendOtpPost$Plain$Response(params?: ApiV10AuthenticationResendOtpPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return apiV10AuthenticationResendOtpPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * resend otp api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationResendOtpPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationResendOtpPost$Plain(params?: ApiV10AuthenticationResendOtpPost$Plain$Params, context?: HttpContext): Observable<UserResponse> {
    return this.apiV10AuthenticationResendOtpPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /**
   * resend otp api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationResendOtpPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationResendOtpPost$Json$Response(params?: ApiV10AuthenticationResendOtpPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return apiV10AuthenticationResendOtpPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * resend otp api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationResendOtpPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationResendOtpPost$Json(params?: ApiV10AuthenticationResendOtpPost$Json$Params, context?: HttpContext): Observable<UserResponse> {
    return this.apiV10AuthenticationResendOtpPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `apiV10AuthenticationRefreshPost()` */
  static readonly ApiV10AuthenticationRefreshPostPath = '/api/v1.0/authentication/refresh';

  /**
   * refresh token api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationRefreshPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationRefreshPost$Plain$Response(params?: ApiV10AuthenticationRefreshPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Tokens>> {
    return apiV10AuthenticationRefreshPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * refresh token api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationRefreshPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationRefreshPost$Plain(params?: ApiV10AuthenticationRefreshPost$Plain$Params, context?: HttpContext): Observable<Tokens> {
    return this.apiV10AuthenticationRefreshPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tokens>): Tokens => r.body)
    );
  }

  /**
   * refresh token api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationRefreshPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationRefreshPost$Json$Response(params?: ApiV10AuthenticationRefreshPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Tokens>> {
    return apiV10AuthenticationRefreshPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * refresh token api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationRefreshPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationRefreshPost$Json(params?: ApiV10AuthenticationRefreshPost$Json$Params, context?: HttpContext): Observable<Tokens> {
    return this.apiV10AuthenticationRefreshPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tokens>): Tokens => r.body)
    );
  }

  /** Path part for operation `apiV10AuthenticationResetPasswordPost()` */
  static readonly ApiV10AuthenticationResetPasswordPostPath = '/api/v1.0/authentication/reset-password';

  /**
   * reset password api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationResetPasswordPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AuthenticationResetPasswordPost$Response(params: ApiV10AuthenticationResetPasswordPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV10AuthenticationResetPasswordPost(this.http, this.rootUrl, params, context);
  }

  /**
   * reset password api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationResetPasswordPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AuthenticationResetPasswordPost(params: ApiV10AuthenticationResetPasswordPost$Params, context?: HttpContext): Observable<void> {
    return this.apiV10AuthenticationResetPasswordPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV10AuthenticationLinkValidationPost()` */
  static readonly ApiV10AuthenticationLinkValidationPostPath = '/api/v1.0/authentication/link-validation';

  /**
   * link validation api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationLinkValidationPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationLinkValidationPost$Plain$Response(params?: ApiV10AuthenticationLinkValidationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiV10AuthenticationLinkValidationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * link validation api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationLinkValidationPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationLinkValidationPost$Plain(params?: ApiV10AuthenticationLinkValidationPost$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.apiV10AuthenticationLinkValidationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * link validation api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationLinkValidationPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationLinkValidationPost$Json$Response(params?: ApiV10AuthenticationLinkValidationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiV10AuthenticationLinkValidationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * link validation api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationLinkValidationPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationLinkValidationPost$Json(params?: ApiV10AuthenticationLinkValidationPost$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.apiV10AuthenticationLinkValidationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiV10AuthenticationSendMailPost()` */
  static readonly ApiV10AuthenticationSendMailPostPath = '/api/v1.0/authentication/send-mail';

  /**
   * reset password send mail api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AuthenticationSendMailPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationSendMailPost$Response(params?: ApiV10AuthenticationSendMailPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV10AuthenticationSendMailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * reset password send mail api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AuthenticationSendMailPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AuthenticationSendMailPost(params?: ApiV10AuthenticationSendMailPost$Params, context?: HttpContext): Observable<void> {
    return this.apiV10AuthenticationSendMailPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
