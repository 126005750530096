import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  appInsights!: ApplicationInsights;

  constructor(private readonly router: Router) {}

  public init(instrumentationKey: string, applicationVersion: string) {
    if (!instrumentationKey) {
      console.warn('App Insights Instrumentation key not configured!');
      return;
    }
    this.initiateAppInsights(instrumentationKey, applicationVersion);
  }
  public logException(error: Error) {
    this.appInsights && this.appInsights.trackException({ exception: error });
  }

  public logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  private initiateAppInsights(
    instrumentationKey: string,
    applicationVersion: string,
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableUnhandledPromiseRejectionTracking: true,
        autoTrackPageVisitTime: true,
        disableExceptionTracking: false,
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView({ name: window.location.href });
    this.appInsights.trackEvent(
      { name: 'Screen Resolution' },
      {
        screenWidth: screen.width,
        screenHeight: screen.height,
        browserWidth: innerWidth,
        browserHeight: innerHeight,
      },
    );
    this.appInsights.context.application.ver = applicationVersion;

    this.createRouterSubscription();
  }

  private createRouterSubscription(): void {
    this.router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe((event: any) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(
            `${activatedComponent.name} ${this.getRouteTemplate(
              event.state.root,
            )}`,
            event.urlAfterRedirects,
          );
        }
      });
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }
    return path;
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }
}
