import { HttpHandlerFn, HttpRequest } from '@angular/common/http';

export function TimezoneInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) {
  const headers = req.headers.set(
    'TimeZone',
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const modifiedRequest = req.clone({ headers });
  return next(modifiedRequest);
}
