/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV10OnDemandTicketsExportPost$Json } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-export-post-json';
import { ApiV10OnDemandTicketsExportPost$Json$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-export-post-json';
import { apiV10OnDemandTicketsExportPost$Plain } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-export-post-plain';
import { ApiV10OnDemandTicketsExportPost$Plain$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-export-post-plain';
import { apiV10OnDemandTicketsPost$Json } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-post-json';
import { ApiV10OnDemandTicketsPost$Json$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-post-json';
import { apiV10OnDemandTicketsPost$Plain } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-post-plain';
import { ApiV10OnDemandTicketsPost$Plain$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-post-plain';
import { apiV10OnDemandTicketsSearchPost$Json } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-search-post-json';
import { ApiV10OnDemandTicketsSearchPost$Json$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-search-post-json';
import { apiV10OnDemandTicketsSearchPost$Plain } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-search-post-plain';
import { ApiV10OnDemandTicketsSearchPost$Plain$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-search-post-plain';
import { apiV10OnDemandTicketsTicketIdGet$Json } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-get-json';
import { ApiV10OnDemandTicketsTicketIdGet$Json$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-get-json';
import { apiV10OnDemandTicketsTicketIdGet$Plain } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-get-plain';
import { ApiV10OnDemandTicketsTicketIdGet$Plain$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-get-plain';
import { apiV10OnDemandTicketsTicketIdPut$Json } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-put-json';
import { ApiV10OnDemandTicketsTicketIdPut$Json$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-put-json';
import { apiV10OnDemandTicketsTicketIdPut$Plain } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-put-plain';
import { ApiV10OnDemandTicketsTicketIdPut$Plain$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-put-plain';
import { apiV10OnDemandTicketsTicketIdRevisionsGet$Json } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-revisions-get-json';
import { ApiV10OnDemandTicketsTicketIdRevisionsGet$Json$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-revisions-get-json';
import { apiV10OnDemandTicketsTicketIdRevisionsGet$Plain } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-revisions-get-plain';
import { ApiV10OnDemandTicketsTicketIdRevisionsGet$Plain$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-revisions-get-plain';
import { apiV10OnDemandTicketsTicketIdStatusPatch$Json } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-status-patch-json';
import { ApiV10OnDemandTicketsTicketIdStatusPatch$Json$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-status-patch-json';
import { apiV10OnDemandTicketsTicketIdStatusPatch$Plain } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-status-patch-plain';
import { ApiV10OnDemandTicketsTicketIdStatusPatch$Plain$Params } from '../fn/on-demand-ticket/api-v-10-on-demand-tickets-ticket-id-status-patch-plain';
import { TicketHistoryResponse } from '../models/ticket-history-response';
import { TicketQueryResponse } from '../models/ticket-query-response';
import { TicketResponse } from '../models/ticket-response';
import { TicketUpdateResponse } from '../models/ticket-update-response';

@Injectable({ providedIn: 'root' })
export class OnDemandTicketService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV10OnDemandTicketsPost()` */
  static readonly ApiV10OnDemandTicketsPostPath = '/api/v1.0/on-demand-tickets';

  /**
   * OnDemand ticket creation api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsPost$Plain$Response(params: ApiV10OnDemandTicketsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10OnDemandTicketsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket creation api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsPost$Plain(params: ApiV10OnDemandTicketsPost$Plain$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10OnDemandTicketsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /**
   * OnDemand ticket creation api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsPost$Json$Response(params: ApiV10OnDemandTicketsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10OnDemandTicketsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket creation api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsPost$Json(params: ApiV10OnDemandTicketsPost$Json$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10OnDemandTicketsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /** Path part for operation `apiV10OnDemandTicketsTicketIdGet()` */
  static readonly ApiV10OnDemandTicketsTicketIdGetPath = '/api/v1.0/on-demand-tickets/{ticketId}';

  /**
   * OnDemand ticket get by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsTicketIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10OnDemandTicketsTicketIdGet$Plain$Response(params: ApiV10OnDemandTicketsTicketIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10OnDemandTicketsTicketIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket get by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsTicketIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10OnDemandTicketsTicketIdGet$Plain(params: ApiV10OnDemandTicketsTicketIdGet$Plain$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10OnDemandTicketsTicketIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /**
   * OnDemand ticket get by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsTicketIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10OnDemandTicketsTicketIdGet$Json$Response(params: ApiV10OnDemandTicketsTicketIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10OnDemandTicketsTicketIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket get by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsTicketIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10OnDemandTicketsTicketIdGet$Json(params: ApiV10OnDemandTicketsTicketIdGet$Json$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10OnDemandTicketsTicketIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /** Path part for operation `apiV10OnDemandTicketsTicketIdPut()` */
  static readonly ApiV10OnDemandTicketsTicketIdPutPath = '/api/v1.0/on-demand-tickets/{ticketId}';

  /**
   * OnDemand ticket update by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsTicketIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsTicketIdPut$Plain$Response(params: ApiV10OnDemandTicketsTicketIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10OnDemandTicketsTicketIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket update by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsTicketIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsTicketIdPut$Plain(params: ApiV10OnDemandTicketsTicketIdPut$Plain$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10OnDemandTicketsTicketIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /**
   * OnDemand ticket update by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsTicketIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsTicketIdPut$Json$Response(params: ApiV10OnDemandTicketsTicketIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10OnDemandTicketsTicketIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket update by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsTicketIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsTicketIdPut$Json(params: ApiV10OnDemandTicketsTicketIdPut$Json$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10OnDemandTicketsTicketIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10OnDemandTicketsSearchPost()` */
  static readonly ApiV10OnDemandTicketsSearchPostPath = '/api/v1.0/on-demand-tickets/search';

  /**
   * OnDemand ticket search api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsSearchPost$Plain$Response(params: ApiV10OnDemandTicketsSearchPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketQueryResponse>>> {
    return apiV10OnDemandTicketsSearchPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket search api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsSearchPost$Plain(params: ApiV10OnDemandTicketsSearchPost$Plain$Params, context?: HttpContext): Observable<Array<TicketQueryResponse>> {
    return this.apiV10OnDemandTicketsSearchPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketQueryResponse>>): Array<TicketQueryResponse> => r.body)
    );
  }

  /**
   * OnDemand ticket search api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsSearchPost$Json$Response(params: ApiV10OnDemandTicketsSearchPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketQueryResponse>>> {
    return apiV10OnDemandTicketsSearchPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket search api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsSearchPost$Json(params: ApiV10OnDemandTicketsSearchPost$Json$Params, context?: HttpContext): Observable<Array<TicketQueryResponse>> {
    return this.apiV10OnDemandTicketsSearchPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketQueryResponse>>): Array<TicketQueryResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10OnDemandTicketsExportPost()` */
  static readonly ApiV10OnDemandTicketsExportPostPath = '/api/v1.0/on-demand-tickets/export';

  /**
   * export ticket.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsExportPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsExportPost$Plain$Response(params: ApiV10OnDemandTicketsExportPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<File>> {
    return apiV10OnDemandTicketsExportPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsExportPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsExportPost$Plain(params: ApiV10OnDemandTicketsExportPost$Plain$Params, context?: HttpContext): Observable<File> {
    return this.apiV10OnDemandTicketsExportPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<File>): File => r.body)
    );
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsExportPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsExportPost$Json$Response(params: ApiV10OnDemandTicketsExportPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<File>> {
    return apiV10OnDemandTicketsExportPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsExportPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsExportPost$Json(params: ApiV10OnDemandTicketsExportPost$Json$Params, context?: HttpContext): Observable<File> {
    return this.apiV10OnDemandTicketsExportPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<File>): File => r.body)
    );
  }

  /** Path part for operation `apiV10OnDemandTicketsTicketIdStatusPatch()` */
  static readonly ApiV10OnDemandTicketsTicketIdStatusPatchPath = '/api/v1.0/on-demand-tickets/{ticketId}/status';

  /**
   * OnDemand ticket status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsTicketIdStatusPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsTicketIdStatusPatch$Plain$Response(params: ApiV10OnDemandTicketsTicketIdStatusPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10OnDemandTicketsTicketIdStatusPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsTicketIdStatusPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsTicketIdStatusPatch$Plain(params: ApiV10OnDemandTicketsTicketIdStatusPatch$Plain$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10OnDemandTicketsTicketIdStatusPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /**
   * OnDemand ticket status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsTicketIdStatusPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsTicketIdStatusPatch$Json$Response(params: ApiV10OnDemandTicketsTicketIdStatusPatch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10OnDemandTicketsTicketIdStatusPatch$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsTicketIdStatusPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10OnDemandTicketsTicketIdStatusPatch$Json(params: ApiV10OnDemandTicketsTicketIdStatusPatch$Json$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10OnDemandTicketsTicketIdStatusPatch$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10OnDemandTicketsTicketIdRevisionsGet()` */
  static readonly ApiV10OnDemandTicketsTicketIdRevisionsGetPath = '/api/v1.0/on-demand-tickets/{ticketId}/revisions';

  /**
   * OnDemand ticket history get by ticket id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsTicketIdRevisionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10OnDemandTicketsTicketIdRevisionsGet$Plain$Response(params: ApiV10OnDemandTicketsTicketIdRevisionsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketHistoryResponse>>> {
    return apiV10OnDemandTicketsTicketIdRevisionsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket history get by ticket id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsTicketIdRevisionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10OnDemandTicketsTicketIdRevisionsGet$Plain(params: ApiV10OnDemandTicketsTicketIdRevisionsGet$Plain$Params, context?: HttpContext): Observable<Array<TicketHistoryResponse>> {
    return this.apiV10OnDemandTicketsTicketIdRevisionsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketHistoryResponse>>): Array<TicketHistoryResponse> => r.body)
    );
  }

  /**
   * OnDemand ticket history get by ticket id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10OnDemandTicketsTicketIdRevisionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10OnDemandTicketsTicketIdRevisionsGet$Json$Response(params: ApiV10OnDemandTicketsTicketIdRevisionsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketHistoryResponse>>> {
    return apiV10OnDemandTicketsTicketIdRevisionsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * OnDemand ticket history get by ticket id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10OnDemandTicketsTicketIdRevisionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10OnDemandTicketsTicketIdRevisionsGet$Json(params: ApiV10OnDemandTicketsTicketIdRevisionsGet$Json$Params, context?: HttpContext): Observable<Array<TicketHistoryResponse>> {
    return this.apiV10OnDemandTicketsTicketIdRevisionsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketHistoryResponse>>): Array<TicketHistoryResponse> => r.body)
    );
  }

}
