import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  Routes,
  provideRouter,
  withComponentInputBinding,
  withHashLocation,
  withRouterConfig
} from '@angular/router';
import { AuthLayoutComponent } from '@authentication/layout/auth-layout.component';
import { AuthorizationInterceptor } from '@business-level-utility/interceptors/add-token-in-request.interceptor';
import { APICallCountInterceptor } from '@business-level-utility/interceptors/api-call-count.interceptor';
import { TimezoneInterceptor } from '@business-level-utility/interceptors/time-zone-header.interceptor';
import { UpdateAccessTokenFromRefreshToken } from '@business-level-utility/interceptors/update-access-token.interceptor';
import { ToasterModule } from '@carestack-components/toaster';
import { AppConfigService } from '@core/app-config.service';
import { AuthGuard } from '@core/auth-guard.service';
import { NoNAuthGuard } from '@core/non-auth-guard.service';
import { AppComponent } from './app.component';

const loadConfigurations = (appConfig: AppConfigService) => () =>
  appConfig.loadConfig();

export const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [NoNAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@authentication/routes').then((m) => m.route),
      },
    ],
  },
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./app.routes').then((m) => m.route),
      },
    ],
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    AppConfigService,
    importProvidersFrom(BrowserModule),
    importProvidersFrom(ToasterModule.forRoot()),
    provideRouter(
      routes,
      withHashLocation(),
      withComponentInputBinding(),
      withRouterConfig({}),
    ),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([
        UpdateAccessTokenFromRefreshToken,
        AuthorizationInterceptor,
        TimezoneInterceptor,
        APICallCountInterceptor,
      ]),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigurations,
      multi: true,
      deps: [AppConfigService],
    },
  ],
};
