/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV10BlockedTicketsExportPost$Json } from '../fn/blocked-ticket/api-v-10-blocked-tickets-export-post-json';
import { ApiV10BlockedTicketsExportPost$Json$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-export-post-json';
import { apiV10BlockedTicketsExportPost$Plain } from '../fn/blocked-ticket/api-v-10-blocked-tickets-export-post-plain';
import { ApiV10BlockedTicketsExportPost$Plain$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-export-post-plain';
import { apiV10BlockedTicketsPost$Json } from '../fn/blocked-ticket/api-v-10-blocked-tickets-post-json';
import { ApiV10BlockedTicketsPost$Json$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-post-json';
import { apiV10BlockedTicketsPost$Plain } from '../fn/blocked-ticket/api-v-10-blocked-tickets-post-plain';
import { ApiV10BlockedTicketsPost$Plain$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-post-plain';
import { apiV10BlockedTicketsSearchPost$Json } from '../fn/blocked-ticket/api-v-10-blocked-tickets-search-post-json';
import { ApiV10BlockedTicketsSearchPost$Json$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-search-post-json';
import { apiV10BlockedTicketsSearchPost$Plain } from '../fn/blocked-ticket/api-v-10-blocked-tickets-search-post-plain';
import { ApiV10BlockedTicketsSearchPost$Plain$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-search-post-plain';
import { apiV10BlockedTicketsTicketIdGet$Json } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-get-json';
import { ApiV10BlockedTicketsTicketIdGet$Json$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-get-json';
import { apiV10BlockedTicketsTicketIdGet$Plain } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-get-plain';
import { ApiV10BlockedTicketsTicketIdGet$Plain$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-get-plain';
import { apiV10BlockedTicketsTicketIdPut$Json } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-put-json';
import { ApiV10BlockedTicketsTicketIdPut$Json$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-put-json';
import { apiV10BlockedTicketsTicketIdPut$Plain } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-put-plain';
import { ApiV10BlockedTicketsTicketIdPut$Plain$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-put-plain';
import { apiV10BlockedTicketsTicketIdRevisionsGet$Json } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-revisions-get-json';
import { ApiV10BlockedTicketsTicketIdRevisionsGet$Json$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-revisions-get-json';
import { apiV10BlockedTicketsTicketIdRevisionsGet$Plain } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-revisions-get-plain';
import { ApiV10BlockedTicketsTicketIdRevisionsGet$Plain$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-revisions-get-plain';
import { apiV10BlockedTicketsTicketIdStatusPatch$Json } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-status-patch-json';
import { ApiV10BlockedTicketsTicketIdStatusPatch$Json$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-status-patch-json';
import { apiV10BlockedTicketsTicketIdStatusPatch$Plain } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-status-patch-plain';
import { ApiV10BlockedTicketsTicketIdStatusPatch$Plain$Params } from '../fn/blocked-ticket/api-v-10-blocked-tickets-ticket-id-status-patch-plain';
import { TicketHistoryResponse } from '../models/ticket-history-response';
import { TicketQueryResponse } from '../models/ticket-query-response';
import { TicketResponse } from '../models/ticket-response';
import { TicketUpdateResponse } from '../models/ticket-update-response';

@Injectable({ providedIn: 'root' })
export class BlockedTicketService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV10BlockedTicketsPost()` */
  static readonly ApiV10BlockedTicketsPostPath = '/api/v1.0/blocked-tickets';

  /**
   * Blocked ticket creation api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsPost$Plain$Response(params: ApiV10BlockedTicketsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10BlockedTicketsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket creation api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsPost$Plain(params: ApiV10BlockedTicketsPost$Plain$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10BlockedTicketsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /**
   * Blocked ticket creation api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsPost$Json$Response(params: ApiV10BlockedTicketsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10BlockedTicketsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket creation api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsPost$Json(params: ApiV10BlockedTicketsPost$Json$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10BlockedTicketsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /** Path part for operation `apiV10BlockedTicketsTicketIdStatusPatch()` */
  static readonly ApiV10BlockedTicketsTicketIdStatusPatchPath = '/api/v1.0/blocked-tickets/{ticketId}/status';

  /**
   * Blocked ticket status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsTicketIdStatusPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsTicketIdStatusPatch$Plain$Response(params: ApiV10BlockedTicketsTicketIdStatusPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10BlockedTicketsTicketIdStatusPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsTicketIdStatusPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsTicketIdStatusPatch$Plain(params: ApiV10BlockedTicketsTicketIdStatusPatch$Plain$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10BlockedTicketsTicketIdStatusPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /**
   * Blocked ticket status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsTicketIdStatusPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsTicketIdStatusPatch$Json$Response(params: ApiV10BlockedTicketsTicketIdStatusPatch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10BlockedTicketsTicketIdStatusPatch$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsTicketIdStatusPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsTicketIdStatusPatch$Json(params: ApiV10BlockedTicketsTicketIdStatusPatch$Json$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10BlockedTicketsTicketIdStatusPatch$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10BlockedTicketsTicketIdGet()` */
  static readonly ApiV10BlockedTicketsTicketIdGetPath = '/api/v1.0/blocked-tickets/{ticketId}';

  /**
   * Blocked ticket get by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsTicketIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10BlockedTicketsTicketIdGet$Plain$Response(params: ApiV10BlockedTicketsTicketIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10BlockedTicketsTicketIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket get by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsTicketIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10BlockedTicketsTicketIdGet$Plain(params: ApiV10BlockedTicketsTicketIdGet$Plain$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10BlockedTicketsTicketIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /**
   * Blocked ticket get by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsTicketIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10BlockedTicketsTicketIdGet$Json$Response(params: ApiV10BlockedTicketsTicketIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10BlockedTicketsTicketIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket get by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsTicketIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10BlockedTicketsTicketIdGet$Json(params: ApiV10BlockedTicketsTicketIdGet$Json$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10BlockedTicketsTicketIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /** Path part for operation `apiV10BlockedTicketsTicketIdPut()` */
  static readonly ApiV10BlockedTicketsTicketIdPutPath = '/api/v1.0/blocked-tickets/{ticketId}';

  /**
   * Blocked ticket update by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsTicketIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsTicketIdPut$Plain$Response(params: ApiV10BlockedTicketsTicketIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10BlockedTicketsTicketIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket update by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsTicketIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsTicketIdPut$Plain(params: ApiV10BlockedTicketsTicketIdPut$Plain$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10BlockedTicketsTicketIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /**
   * Blocked ticket update by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsTicketIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsTicketIdPut$Json$Response(params: ApiV10BlockedTicketsTicketIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10BlockedTicketsTicketIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket update by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsTicketIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsTicketIdPut$Json(params: ApiV10BlockedTicketsTicketIdPut$Json$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10BlockedTicketsTicketIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10BlockedTicketsSearchPost()` */
  static readonly ApiV10BlockedTicketsSearchPostPath = '/api/v1.0/blocked-tickets/search';

  /**
   * Blocked ticket search api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsSearchPost$Plain$Response(params: ApiV10BlockedTicketsSearchPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketQueryResponse>>> {
    return apiV10BlockedTicketsSearchPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket search api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsSearchPost$Plain(params: ApiV10BlockedTicketsSearchPost$Plain$Params, context?: HttpContext): Observable<Array<TicketQueryResponse>> {
    return this.apiV10BlockedTicketsSearchPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketQueryResponse>>): Array<TicketQueryResponse> => r.body)
    );
  }

  /**
   * Blocked ticket search api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsSearchPost$Json$Response(params: ApiV10BlockedTicketsSearchPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketQueryResponse>>> {
    return apiV10BlockedTicketsSearchPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket search api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsSearchPost$Json(params: ApiV10BlockedTicketsSearchPost$Json$Params, context?: HttpContext): Observable<Array<TicketQueryResponse>> {
    return this.apiV10BlockedTicketsSearchPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketQueryResponse>>): Array<TicketQueryResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10BlockedTicketsExportPost()` */
  static readonly ApiV10BlockedTicketsExportPostPath = '/api/v1.0/blocked-tickets/export';

  /**
   * export ticket.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsExportPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsExportPost$Plain$Response(params: ApiV10BlockedTicketsExportPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<File>> {
    return apiV10BlockedTicketsExportPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsExportPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsExportPost$Plain(params: ApiV10BlockedTicketsExportPost$Plain$Params, context?: HttpContext): Observable<File> {
    return this.apiV10BlockedTicketsExportPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<File>): File => r.body)
    );
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsExportPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsExportPost$Json$Response(params: ApiV10BlockedTicketsExportPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<File>> {
    return apiV10BlockedTicketsExportPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsExportPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10BlockedTicketsExportPost$Json(params: ApiV10BlockedTicketsExportPost$Json$Params, context?: HttpContext): Observable<File> {
    return this.apiV10BlockedTicketsExportPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<File>): File => r.body)
    );
  }

  /** Path part for operation `apiV10BlockedTicketsTicketIdRevisionsGet()` */
  static readonly ApiV10BlockedTicketsTicketIdRevisionsGetPath = '/api/v1.0/blocked-tickets/{ticketId}/revisions';

  /**
   * Blocked ticket history get by ticket id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsTicketIdRevisionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10BlockedTicketsTicketIdRevisionsGet$Plain$Response(params: ApiV10BlockedTicketsTicketIdRevisionsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketHistoryResponse>>> {
    return apiV10BlockedTicketsTicketIdRevisionsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket history get by ticket id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsTicketIdRevisionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10BlockedTicketsTicketIdRevisionsGet$Plain(params: ApiV10BlockedTicketsTicketIdRevisionsGet$Plain$Params, context?: HttpContext): Observable<Array<TicketHistoryResponse>> {
    return this.apiV10BlockedTicketsTicketIdRevisionsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketHistoryResponse>>): Array<TicketHistoryResponse> => r.body)
    );
  }

  /**
   * Blocked ticket history get by ticket id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10BlockedTicketsTicketIdRevisionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10BlockedTicketsTicketIdRevisionsGet$Json$Response(params: ApiV10BlockedTicketsTicketIdRevisionsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketHistoryResponse>>> {
    return apiV10BlockedTicketsTicketIdRevisionsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocked ticket history get by ticket id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10BlockedTicketsTicketIdRevisionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10BlockedTicketsTicketIdRevisionsGet$Json(params: ApiV10BlockedTicketsTicketIdRevisionsGet$Json$Params, context?: HttpContext): Observable<Array<TicketHistoryResponse>> {
    return this.apiV10BlockedTicketsTicketIdRevisionsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketHistoryResponse>>): Array<TicketHistoryResponse> => r.body)
    );
  }

}
