import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@api-service/*';
import { ActiveUserCachedDetailsService } from '@business-level-utility/cache/active-user-cached-details.service';
import { AuthenticationService } from '@business-level-utility/services/authentication.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
let isRefreshingToken = false;

export function UpdateAccessTokenFromRefreshToken(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const authApiService = inject(AuthService);
  const authenticationService = inject(AuthenticationService);
  const activeUserCachedDetailsService = inject(ActiveUserCachedDetailsService);

  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === HttpStatusCode.Unauthorized && !isRefreshingToken) {
        isRefreshingToken = true;
        return activeUserCachedDetailsService.refreshToken$.pipe(
          take(1),
          switchMap((refreshToken: any) => {
            return authApiService
              .apiV10AuthenticationRefreshPost$Json({
                refreshToken,
              })
              .pipe(
                tap(({ accessToken, refreshToken }) => {
                  localStorage.setItem('userToken', accessToken as string);
                  localStorage.setItem(
                    'refreshToken',
                    refreshToken?.token as string,
                  );
                  activeUserCachedDetailsService.refreshToken.next(
                    refreshToken?.token as string,
                  );
                }),
                finalize(() => {
                  isRefreshingToken = false;
                }),
              );
          }),
          switchMap(() => next(request)),
        );
      }

      if (error.status === 403) {
        authenticationService.logoutUser();
      }
      return throwError(() => error);
    }),
  );
}
