/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LocationUpdateRequest } from '../../models/location-update-request';
import { LocationUpdateResponse } from '../../models/location-update-response';

export interface ApiV10LocationsLocationIdPut$Json$Params {
  locationId: number;
      body: LocationUpdateRequest
}

export function apiV10LocationsLocationIdPut$Json(http: HttpClient, rootUrl: string, params: ApiV10LocationsLocationIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationUpdateResponse>> {
  const rb = new RequestBuilder(rootUrl, apiV10LocationsLocationIdPut$Json.PATH, 'put');
  if (params) {
    rb.path('locationId', params.locationId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LocationUpdateResponse>;
    })
  );
}

apiV10LocationsLocationIdPut$Json.PATH = '/api/v1.0/locations/{locationId}';
