import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiConfiguration } from '../../../../api/api-configuration';
import { AppConfig } from './app.config';
import { MonitoringService } from './monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private readonly httpClient: HttpClient;

  public config = new AppConfig();

  constructor(
    private readonly handler: HttpBackend,
    private readonly apiConfiguration: ApiConfiguration,
    private readonly monitoringService: MonitoringService,
  ) {
    this.httpClient = new HttpClient(this.handler);
  }

  loadConfig(): Observable<any> {
    return this.httpClient.get('../assets/config.json').pipe(
      map((appConfig: any) => {
        this.apiConfiguration.rootUrl = appConfig.serverUrl;
        this.monitoringService.init(
          appConfig.appInsightsKey,
          appConfig?.version,
        );
        this.config = appConfig;
        return appConfig;
      }),
    );
  }
}
