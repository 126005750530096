/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV10TicketClassificationsGet$Json } from '../fn/ticket-classification/api-v-10-ticket-classifications-get-json';
import { ApiV10TicketClassificationsGet$Json$Params } from '../fn/ticket-classification/api-v-10-ticket-classifications-get-json';
import { apiV10TicketClassificationsGet$Plain } from '../fn/ticket-classification/api-v-10-ticket-classifications-get-plain';
import { ApiV10TicketClassificationsGet$Plain$Params } from '../fn/ticket-classification/api-v-10-ticket-classifications-get-plain';
import { apiV10TicketClassificationsPost$Json } from '../fn/ticket-classification/api-v-10-ticket-classifications-post-json';
import { ApiV10TicketClassificationsPost$Json$Params } from '../fn/ticket-classification/api-v-10-ticket-classifications-post-json';
import { apiV10TicketClassificationsPost$Plain } from '../fn/ticket-classification/api-v-10-ticket-classifications-post-plain';
import { ApiV10TicketClassificationsPost$Plain$Params } from '../fn/ticket-classification/api-v-10-ticket-classifications-post-plain';
import { apiV10TicketClassificationsSearchPost$Json } from '../fn/ticket-classification/api-v-10-ticket-classifications-search-post-json';
import { ApiV10TicketClassificationsSearchPost$Json$Params } from '../fn/ticket-classification/api-v-10-ticket-classifications-search-post-json';
import { apiV10TicketClassificationsSearchPost$Plain } from '../fn/ticket-classification/api-v-10-ticket-classifications-search-post-plain';
import { ApiV10TicketClassificationsSearchPost$Plain$Params } from '../fn/ticket-classification/api-v-10-ticket-classifications-search-post-plain';
import { apiV10TicketClassificationsTicketClassificationIdGet$Json } from '../fn/ticket-classification/api-v-10-ticket-classifications-ticket-classification-id-get-json';
import { ApiV10TicketClassificationsTicketClassificationIdGet$Json$Params } from '../fn/ticket-classification/api-v-10-ticket-classifications-ticket-classification-id-get-json';
import { apiV10TicketClassificationsTicketClassificationIdGet$Plain } from '../fn/ticket-classification/api-v-10-ticket-classifications-ticket-classification-id-get-plain';
import { ApiV10TicketClassificationsTicketClassificationIdGet$Plain$Params } from '../fn/ticket-classification/api-v-10-ticket-classifications-ticket-classification-id-get-plain';
import { apiV10TicketClassificationsTicketClassificationIdPut$Json } from '../fn/ticket-classification/api-v-10-ticket-classifications-ticket-classification-id-put-json';
import { ApiV10TicketClassificationsTicketClassificationIdPut$Json$Params } from '../fn/ticket-classification/api-v-10-ticket-classifications-ticket-classification-id-put-json';
import { apiV10TicketClassificationsTicketClassificationIdPut$Plain } from '../fn/ticket-classification/api-v-10-ticket-classifications-ticket-classification-id-put-plain';
import { ApiV10TicketClassificationsTicketClassificationIdPut$Plain$Params } from '../fn/ticket-classification/api-v-10-ticket-classifications-ticket-classification-id-put-plain';
import { TicketClassificationResponse } from '../models/ticket-classification-response';
import { TicketClassificationUpdateResponse } from '../models/ticket-classification-update-response';

@Injectable({ providedIn: 'root' })
export class TicketClassificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV10TicketClassificationsGet()` */
  static readonly ApiV10TicketClassificationsGetPath = '/api/v1.0/ticket-classifications';

  /**
   * get all ticket classification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketClassificationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketClassificationsGet$Plain$Response(params?: ApiV10TicketClassificationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketClassificationResponse>>> {
    return apiV10TicketClassificationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get all ticket classification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketClassificationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketClassificationsGet$Plain(params?: ApiV10TicketClassificationsGet$Plain$Params, context?: HttpContext): Observable<Array<TicketClassificationResponse>> {
    return this.apiV10TicketClassificationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketClassificationResponse>>): Array<TicketClassificationResponse> => r.body)
    );
  }

  /**
   * get all ticket classification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketClassificationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketClassificationsGet$Json$Response(params?: ApiV10TicketClassificationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketClassificationResponse>>> {
    return apiV10TicketClassificationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get all ticket classification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketClassificationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketClassificationsGet$Json(params?: ApiV10TicketClassificationsGet$Json$Params, context?: HttpContext): Observable<Array<TicketClassificationResponse>> {
    return this.apiV10TicketClassificationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketClassificationResponse>>): Array<TicketClassificationResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10TicketClassificationsPost()` */
  static readonly ApiV10TicketClassificationsPostPath = '/api/v1.0/ticket-classifications';

  /**
   * ticket classification creation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketClassificationsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsPost$Plain$Response(params?: ApiV10TicketClassificationsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketClassificationResponse>> {
    return apiV10TicketClassificationsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * ticket classification creation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketClassificationsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsPost$Plain(params?: ApiV10TicketClassificationsPost$Plain$Params, context?: HttpContext): Observable<TicketClassificationResponse> {
    return this.apiV10TicketClassificationsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketClassificationResponse>): TicketClassificationResponse => r.body)
    );
  }

  /**
   * ticket classification creation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketClassificationsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsPost$Json$Response(params?: ApiV10TicketClassificationsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketClassificationResponse>> {
    return apiV10TicketClassificationsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * ticket classification creation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketClassificationsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsPost$Json(params?: ApiV10TicketClassificationsPost$Json$Params, context?: HttpContext): Observable<TicketClassificationResponse> {
    return this.apiV10TicketClassificationsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketClassificationResponse>): TicketClassificationResponse => r.body)
    );
  }

  /** Path part for operation `apiV10TicketClassificationsTicketClassificationIdGet()` */
  static readonly ApiV10TicketClassificationsTicketClassificationIdGetPath = '/api/v1.0/ticket-classifications/{ticketClassificationId}';

  /**
   * get ticket classification details by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketClassificationsTicketClassificationIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketClassificationsTicketClassificationIdGet$Plain$Response(params: ApiV10TicketClassificationsTicketClassificationIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketClassificationResponse>> {
    return apiV10TicketClassificationsTicketClassificationIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get ticket classification details by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketClassificationsTicketClassificationIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketClassificationsTicketClassificationIdGet$Plain(params: ApiV10TicketClassificationsTicketClassificationIdGet$Plain$Params, context?: HttpContext): Observable<TicketClassificationResponse> {
    return this.apiV10TicketClassificationsTicketClassificationIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketClassificationResponse>): TicketClassificationResponse => r.body)
    );
  }

  /**
   * get ticket classification details by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketClassificationsTicketClassificationIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketClassificationsTicketClassificationIdGet$Json$Response(params: ApiV10TicketClassificationsTicketClassificationIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketClassificationResponse>> {
    return apiV10TicketClassificationsTicketClassificationIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get ticket classification details by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketClassificationsTicketClassificationIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10TicketClassificationsTicketClassificationIdGet$Json(params: ApiV10TicketClassificationsTicketClassificationIdGet$Json$Params, context?: HttpContext): Observable<TicketClassificationResponse> {
    return this.apiV10TicketClassificationsTicketClassificationIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketClassificationResponse>): TicketClassificationResponse => r.body)
    );
  }

  /** Path part for operation `apiV10TicketClassificationsTicketClassificationIdPut()` */
  static readonly ApiV10TicketClassificationsTicketClassificationIdPutPath = '/api/v1.0/ticket-classifications/{ticketClassificationId}';

  /**
   * ticket classification update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketClassificationsTicketClassificationIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsTicketClassificationIdPut$Plain$Response(params: ApiV10TicketClassificationsTicketClassificationIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketClassificationUpdateResponse>> {
    return apiV10TicketClassificationsTicketClassificationIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * ticket classification update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketClassificationsTicketClassificationIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsTicketClassificationIdPut$Plain(params: ApiV10TicketClassificationsTicketClassificationIdPut$Plain$Params, context?: HttpContext): Observable<TicketClassificationUpdateResponse> {
    return this.apiV10TicketClassificationsTicketClassificationIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketClassificationUpdateResponse>): TicketClassificationUpdateResponse => r.body)
    );
  }

  /**
   * ticket classification update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketClassificationsTicketClassificationIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsTicketClassificationIdPut$Json$Response(params: ApiV10TicketClassificationsTicketClassificationIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketClassificationUpdateResponse>> {
    return apiV10TicketClassificationsTicketClassificationIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * ticket classification update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketClassificationsTicketClassificationIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsTicketClassificationIdPut$Json(params: ApiV10TicketClassificationsTicketClassificationIdPut$Json$Params, context?: HttpContext): Observable<TicketClassificationUpdateResponse> {
    return this.apiV10TicketClassificationsTicketClassificationIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketClassificationUpdateResponse>): TicketClassificationUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10TicketClassificationsSearchPost()` */
  static readonly ApiV10TicketClassificationsSearchPostPath = '/api/v1.0/ticket-classifications/search';

  /**
   * ticket classification search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketClassificationsSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsSearchPost$Plain$Response(params?: ApiV10TicketClassificationsSearchPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketClassificationResponse>>> {
    return apiV10TicketClassificationsSearchPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * ticket classification search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketClassificationsSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsSearchPost$Plain(params?: ApiV10TicketClassificationsSearchPost$Plain$Params, context?: HttpContext): Observable<Array<TicketClassificationResponse>> {
    return this.apiV10TicketClassificationsSearchPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketClassificationResponse>>): Array<TicketClassificationResponse> => r.body)
    );
  }

  /**
   * ticket classification search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10TicketClassificationsSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsSearchPost$Json$Response(params?: ApiV10TicketClassificationsSearchPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketClassificationResponse>>> {
    return apiV10TicketClassificationsSearchPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * ticket classification search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10TicketClassificationsSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10TicketClassificationsSearchPost$Json(params?: ApiV10TicketClassificationsSearchPost$Json$Params, context?: HttpContext): Observable<Array<TicketClassificationResponse>> {
    return this.apiV10TicketClassificationsSearchPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketClassificationResponse>>): Array<TicketClassificationResponse> => r.body)
    );
  }

}
