import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  delayWhen,
  distinctUntilChanged,
  of,
  timer,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  activeCounter = 0;

  isLoading = new BehaviorSubject<boolean>(true);
  isLoading$ = this.isLoading.pipe(
    distinctUntilChanged(),
    debounceTime(0),
    delayWhen((value) => (!value ? timer(200) : of(null))),
  );

  increment(): void {
    this.activeCounter++;
    this.isLoading.next(this.activeCounter !== 0);
  }

  decrement(): void {
    this.activeCounter--;
    this.isLoading.next(this.activeCounter !== 0);
  }
}
