/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountDetailResponse } from '../models/account-detail-response';
import { AccountQueryResponse } from '../models/account-query-response';
import { AccountsDetailResponse } from '../models/accounts-detail-response';
import { AccountUpdateResponse } from '../models/account-update-response';
import { apiV10AccountsAccountIdGet$Json } from '../fn/account/api-v-10-accounts-account-id-get-json';
import { ApiV10AccountsAccountIdGet$Json$Params } from '../fn/account/api-v-10-accounts-account-id-get-json';
import { apiV10AccountsAccountIdGet$Plain } from '../fn/account/api-v-10-accounts-account-id-get-plain';
import { ApiV10AccountsAccountIdGet$Plain$Params } from '../fn/account/api-v-10-accounts-account-id-get-plain';
import { apiV10AccountsAccountIdIsActivePatch$Json } from '../fn/account/api-v-10-accounts-account-id-is-active-patch-json';
import { ApiV10AccountsAccountIdIsActivePatch$Json$Params } from '../fn/account/api-v-10-accounts-account-id-is-active-patch-json';
import { apiV10AccountsAccountIdIsActivePatch$Plain } from '../fn/account/api-v-10-accounts-account-id-is-active-patch-plain';
import { ApiV10AccountsAccountIdIsActivePatch$Plain$Params } from '../fn/account/api-v-10-accounts-account-id-is-active-patch-plain';
import { apiV10AccountsAccountIdPut$Json } from '../fn/account/api-v-10-accounts-account-id-put-json';
import { ApiV10AccountsAccountIdPut$Json$Params } from '../fn/account/api-v-10-accounts-account-id-put-json';
import { apiV10AccountsAccountIdPut$Plain } from '../fn/account/api-v-10-accounts-account-id-put-plain';
import { ApiV10AccountsAccountIdPut$Plain$Params } from '../fn/account/api-v-10-accounts-account-id-put-plain';
import { apiV10AccountsEloTypeAccountsGet$Json } from '../fn/account/api-v-10-accounts-elo-type-accounts-get-json';
import { ApiV10AccountsEloTypeAccountsGet$Json$Params } from '../fn/account/api-v-10-accounts-elo-type-accounts-get-json';
import { apiV10AccountsEloTypeAccountsGet$Plain } from '../fn/account/api-v-10-accounts-elo-type-accounts-get-plain';
import { ApiV10AccountsEloTypeAccountsGet$Plain$Params } from '../fn/account/api-v-10-accounts-elo-type-accounts-get-plain';
import { apiV10AccountsGet$Json } from '../fn/account/api-v-10-accounts-get-json';
import { ApiV10AccountsGet$Json$Params } from '../fn/account/api-v-10-accounts-get-json';
import { apiV10AccountsGet$Plain } from '../fn/account/api-v-10-accounts-get-plain';
import { ApiV10AccountsGet$Plain$Params } from '../fn/account/api-v-10-accounts-get-plain';
import { apiV10AccountsPost$Json } from '../fn/account/api-v-10-accounts-post-json';
import { ApiV10AccountsPost$Json$Params } from '../fn/account/api-v-10-accounts-post-json';
import { apiV10AccountsPost$Plain } from '../fn/account/api-v-10-accounts-post-plain';
import { ApiV10AccountsPost$Plain$Params } from '../fn/account/api-v-10-accounts-post-plain';
import { apiV10AccountsSearchPost$Json } from '../fn/account/api-v-10-accounts-search-post-json';
import { ApiV10AccountsSearchPost$Json$Params } from '../fn/account/api-v-10-accounts-search-post-json';
import { apiV10AccountsSearchPost$Plain } from '../fn/account/api-v-10-accounts-search-post-plain';
import { ApiV10AccountsSearchPost$Plain$Params } from '../fn/account/api-v-10-accounts-search-post-plain';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV10AccountsGet()` */
  static readonly ApiV10AccountsGetPath = '/api/v1.0/accounts';

  /**
   * get all accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsGet$Plain$Response(params?: ApiV10AccountsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountsDetailResponse>>> {
    return apiV10AccountsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get all accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsGet$Plain(params?: ApiV10AccountsGet$Plain$Params, context?: HttpContext): Observable<Array<AccountsDetailResponse>> {
    return this.apiV10AccountsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountsDetailResponse>>): Array<AccountsDetailResponse> => r.body)
    );
  }

  /**
   * get all accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsGet$Json$Response(params?: ApiV10AccountsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountsDetailResponse>>> {
    return apiV10AccountsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get all accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsGet$Json(params?: ApiV10AccountsGet$Json$Params, context?: HttpContext): Observable<Array<AccountsDetailResponse>> {
    return this.apiV10AccountsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountsDetailResponse>>): Array<AccountsDetailResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10AccountsPost()` */
  static readonly ApiV10AccountsPostPath = '/api/v1.0/accounts';

  /**
   * account create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsPost$Plain$Response(params: ApiV10AccountsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountUpdateResponse>> {
    return apiV10AccountsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * account create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsPost$Plain(params: ApiV10AccountsPost$Plain$Params, context?: HttpContext): Observable<AccountUpdateResponse> {
    return this.apiV10AccountsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUpdateResponse>): AccountUpdateResponse => r.body)
    );
  }

  /**
   * account create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsPost$Json$Response(params: ApiV10AccountsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountUpdateResponse>> {
    return apiV10AccountsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * account create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsPost$Json(params: ApiV10AccountsPost$Json$Params, context?: HttpContext): Observable<AccountUpdateResponse> {
    return this.apiV10AccountsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUpdateResponse>): AccountUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10AccountsAccountIdGet()` */
  static readonly ApiV10AccountsAccountIdGetPath = '/api/v1.0/accounts/{accountId}';

  /**
   * get account details by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsAccountIdGet$Plain$Response(params: ApiV10AccountsAccountIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountDetailResponse>> {
    return apiV10AccountsAccountIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get account details by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsAccountIdGet$Plain(params: ApiV10AccountsAccountIdGet$Plain$Params, context?: HttpContext): Observable<AccountDetailResponse> {
    return this.apiV10AccountsAccountIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountDetailResponse>): AccountDetailResponse => r.body)
    );
  }

  /**
   * get account details by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsAccountIdGet$Json$Response(params: ApiV10AccountsAccountIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountDetailResponse>> {
    return apiV10AccountsAccountIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get account details by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsAccountIdGet$Json(params: ApiV10AccountsAccountIdGet$Json$Params, context?: HttpContext): Observable<AccountDetailResponse> {
    return this.apiV10AccountsAccountIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountDetailResponse>): AccountDetailResponse => r.body)
    );
  }

  /** Path part for operation `apiV10AccountsAccountIdPut()` */
  static readonly ApiV10AccountsAccountIdPutPath = '/api/v1.0/accounts/{accountId}';

  /**
   * update account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsAccountIdPut$Plain$Response(params: ApiV10AccountsAccountIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountUpdateResponse>> {
    return apiV10AccountsAccountIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * update account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsAccountIdPut$Plain(params: ApiV10AccountsAccountIdPut$Plain$Params, context?: HttpContext): Observable<AccountUpdateResponse> {
    return this.apiV10AccountsAccountIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUpdateResponse>): AccountUpdateResponse => r.body)
    );
  }

  /**
   * update account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsAccountIdPut$Json$Response(params: ApiV10AccountsAccountIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountUpdateResponse>> {
    return apiV10AccountsAccountIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * update account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsAccountIdPut$Json(params: ApiV10AccountsAccountIdPut$Json$Params, context?: HttpContext): Observable<AccountUpdateResponse> {
    return this.apiV10AccountsAccountIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUpdateResponse>): AccountUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10AccountsSearchPost()` */
  static readonly ApiV10AccountsSearchPostPath = '/api/v1.0/accounts/search';

  /**
   * account search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsSearchPost$Plain$Response(params: ApiV10AccountsSearchPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountQueryResponse>>> {
    return apiV10AccountsSearchPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * account search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsSearchPost$Plain(params: ApiV10AccountsSearchPost$Plain$Params, context?: HttpContext): Observable<Array<AccountQueryResponse>> {
    return this.apiV10AccountsSearchPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountQueryResponse>>): Array<AccountQueryResponse> => r.body)
    );
  }

  /**
   * account search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsSearchPost$Json$Response(params: ApiV10AccountsSearchPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountQueryResponse>>> {
    return apiV10AccountsSearchPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * account search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsSearchPost$Json(params: ApiV10AccountsSearchPost$Json$Params, context?: HttpContext): Observable<Array<AccountQueryResponse>> {
    return this.apiV10AccountsSearchPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountQueryResponse>>): Array<AccountQueryResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10AccountsAccountIdIsActivePatch()` */
  static readonly ApiV10AccountsAccountIdIsActivePatchPath = '/api/v1.0/accounts/{accountId}/is-active';

  /**
   * account status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsAccountIdIsActivePatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsAccountIdIsActivePatch$Plain$Response(params: ApiV10AccountsAccountIdIsActivePatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountUpdateResponse>> {
    return apiV10AccountsAccountIdIsActivePatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * account status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsAccountIdIsActivePatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsAccountIdIsActivePatch$Plain(params: ApiV10AccountsAccountIdIsActivePatch$Plain$Params, context?: HttpContext): Observable<AccountUpdateResponse> {
    return this.apiV10AccountsAccountIdIsActivePatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUpdateResponse>): AccountUpdateResponse => r.body)
    );
  }

  /**
   * account status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsAccountIdIsActivePatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsAccountIdIsActivePatch$Json$Response(params: ApiV10AccountsAccountIdIsActivePatch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountUpdateResponse>> {
    return apiV10AccountsAccountIdIsActivePatch$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * account status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsAccountIdIsActivePatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10AccountsAccountIdIsActivePatch$Json(params: ApiV10AccountsAccountIdIsActivePatch$Json$Params, context?: HttpContext): Observable<AccountUpdateResponse> {
    return this.apiV10AccountsAccountIdIsActivePatch$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUpdateResponse>): AccountUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10AccountsEloTypeAccountsGet()` */
  static readonly ApiV10AccountsEloTypeAccountsGetPath = '/api/v1.0/accounts/elo-type-accounts';

  /**
   * Accounts where ELO Checks are enabled.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsEloTypeAccountsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsEloTypeAccountsGet$Plain$Response(params?: ApiV10AccountsEloTypeAccountsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountsDetailResponse>>> {
    return apiV10AccountsEloTypeAccountsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Accounts where ELO Checks are enabled.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsEloTypeAccountsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsEloTypeAccountsGet$Plain(params?: ApiV10AccountsEloTypeAccountsGet$Plain$Params, context?: HttpContext): Observable<Array<AccountsDetailResponse>> {
    return this.apiV10AccountsEloTypeAccountsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountsDetailResponse>>): Array<AccountsDetailResponse> => r.body)
    );
  }

  /**
   * Accounts where ELO Checks are enabled.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10AccountsEloTypeAccountsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsEloTypeAccountsGet$Json$Response(params?: ApiV10AccountsEloTypeAccountsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountsDetailResponse>>> {
    return apiV10AccountsEloTypeAccountsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Accounts where ELO Checks are enabled.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10AccountsEloTypeAccountsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10AccountsEloTypeAccountsGet$Json(params?: ApiV10AccountsEloTypeAccountsGet$Json$Params, context?: HttpContext): Observable<Array<AccountsDetailResponse>> {
    return this.apiV10AccountsEloTypeAccountsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountsDetailResponse>>): Array<AccountsDetailResponse> => r.body)
    );
  }

}
