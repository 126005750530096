import { Injectable, inject } from '@angular/core';
import { Permission, Role } from '@api-model/*';
import { BehaviorSubject, map, shareReplay } from 'rxjs';
import { FlaggedEnumService } from '../../../../utility/src/lib/service/flagged-enum.service';
import { JwtHelperService } from '../../../../utility/src/lib/service/jwt-decoder.helper.service';

type UserDetailsType = {
  id: number;
  name: string;
  role: number;
  email: string;
  tokens: {
    accessToken: string;
    refreshToken: {
      token: string;
      created: string;
      expires: string;
    };
  };
  hashString2FA: string;
  twoFactorAuth: true;
};

@Injectable({ providedIn: 'root' })
export class ActiveUserCachedDetailsService {
  jwtTokeService = inject(JwtHelperService);
  flaggedEnumService = inject(FlaggedEnumService);

  activeUserName = new BehaviorSubject<string | null>('');
  activeUserName$ = this.activeUserName.pipe(shareReplay(1));

  activeUserAccountId = new BehaviorSubject<number | null>(null);
  activeUserAccountId$ = this.activeUserAccountId.pipe(shareReplay(1));

  activeUserRoles = new BehaviorSubject<number | null>(null);
  activeUserRoles$ = this.activeUserRoles.pipe(shareReplay(1));

  activeUserPermissions = new BehaviorSubject<Array<number> | null>([]);
  activeUserPermissions$ = this.activeUserPermissions.pipe(shareReplay(1));

  activeUserTwoFacAuthEnabled = new BehaviorSubject<boolean | null>(null);
  activeUserTwoFacAuthEnabled$ = this.activeUserTwoFacAuthEnabled.pipe(
    shareReplay(1),
  );

  activeUserUserId = new BehaviorSubject<number | null>(null);
  activeUserUserId$ = this.activeUserUserId.pipe(shareReplay(1));

  activeUserEmailId = new BehaviorSubject<string | null>(null);
  activeUserEmailId$ = this.activeUserEmailId.pipe(shareReplay(1));

  refreshToken = new BehaviorSubject<string | null>(null);
  refreshToken$ = this.refreshToken.pipe(shareReplay(1));

  isActiveUserClientUser$ = this.activeUserRoles$.pipe(
    map((role) => {
      return role ? [Role.ClientAdmin, Role.ClientAgent].includes(role) : false;
    }),
  );

  isActiveUserHasPermissions = (verifyForPermissions: Permission[]) =>
    this.activeUserPermissions$.pipe(
      map((activeUserPermissions) => {
        return activeUserPermissions
          ? activeUserPermissions.some((activeUserPermission) =>
              verifyForPermissions.includes(activeUserPermission),
            )
          : false;
      }),
    );

  setActiveUserDetails(): void {
    const accessToken = localStorage.getItem('userToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const role = parseInt(localStorage.getItem('userRole') ?? '');

    if (!accessToken) {
      return;
    }

    const decodedAccessToken = this.jwtTokeService.decodeToken(accessToken);
    const permissions = this.flaggedEnumService.enumToFlags(
      +decodedAccessToken.Permission,
      Permission,
    );

    this.activeUserPermissions.next(permissions);
    this.activeUserAccountId.next(decodedAccessToken.AccountId);
    this.activeUserRoles.next(role);
    this.refreshToken.next(refreshToken);
  }

  unsetActiveUserDetails(): void {
    // this.activeUserPermissions.next(null);
    // this.activeUserAccountId.next(null);
    // this.activeUserRoles.next(null);
    // this.activeUserName.next(null);
    // this.activeUserTwoFacAuthEnabled.next(null);
    // this.activeUserEmailId.next(null);
    // this.activeUserUserId.next(null);
  }
}
