import { Injectable } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CdkPortalService {
  constructor() {}

  pageLevelTopRightControlPortalTemplate = new Subject<any>();
  pageLevelTopRightControlPortalTemplate$ =
    this.pageLevelTopRightControlPortalTemplate.asObservable();

  tabLevelTopRightControlPortalTemplate = new Subject<any>();
  tabLevelTopRightControlPortalTemplate$ =
    this.tabLevelTopRightControlPortalTemplate.pipe(debounceTime(10));
}
