import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoaderService } from '@business-level-utility/services/loader.service';
import { finalize } from 'rxjs/operators';

export function APICallCountInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) {
  const loaderService = inject(LoaderService);
  loaderService.increment();

  return next(request).pipe(
    finalize(() => {
      loaderService.decrement();
    }),
  );
}
