import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { loginPageUrl } from '@constants/route-urls/authentication.route-urls';
import { JwtHelperService } from '@utility/service/jwt-decoder.helper.service';
import { Observable, filter, fromEvent } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  jwtTokeService = inject(JwtHelperService);
  private readonly userTokenKey = 'userToken';
  private router = inject(Router);

  public isUserAuthenticated(): boolean {
    const userToken = localStorage.getItem('userToken') as string;
    return !!userToken;
  }

  public logoutUser(): void {
    localStorage.removeItem('userToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRole');
    this.router.navigateByUrl(loginPageUrl);
    window.location.reload();
  }

  public getTokenStorageEventListener(): Observable<StorageEvent> {
    return fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((event) => event.key === this.userTokenKey),
    );
  }
}
