import { Injectable } from '@angular/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';

type CustomJwtPayload = JwtPayload & {
  Permission: string;
  AccountId: number;
};

@Injectable({ providedIn: 'root' })
export class JwtHelperService {
  readonly decodeToken = (token: string): CustomJwtPayload => {
    this.isTokenInvalid(token);
    return jwtDecode<CustomJwtPayload>(token);
  };

  private readonly isTokenInvalid = (token: string) => {
    if (typeof token !== 'string' || !token) {
      throw new Error('Invalid token provided');
    }
  };
}
