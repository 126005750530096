/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV10MedicalTicketsExportPost$Json } from '../fn/medical-ticket/api-v-10-medical-tickets-export-post-json';
import { ApiV10MedicalTicketsExportPost$Json$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-export-post-json';
import { apiV10MedicalTicketsExportPost$Plain } from '../fn/medical-ticket/api-v-10-medical-tickets-export-post-plain';
import { ApiV10MedicalTicketsExportPost$Plain$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-export-post-plain';
import { apiV10MedicalTicketsPost$Json } from '../fn/medical-ticket/api-v-10-medical-tickets-post-json';
import { ApiV10MedicalTicketsPost$Json$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-post-json';
import { apiV10MedicalTicketsPost$Plain } from '../fn/medical-ticket/api-v-10-medical-tickets-post-plain';
import { ApiV10MedicalTicketsPost$Plain$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-post-plain';
import { apiV10MedicalTicketsSearchPost$Json } from '../fn/medical-ticket/api-v-10-medical-tickets-search-post-json';
import { ApiV10MedicalTicketsSearchPost$Json$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-search-post-json';
import { apiV10MedicalTicketsSearchPost$Plain } from '../fn/medical-ticket/api-v-10-medical-tickets-search-post-plain';
import { ApiV10MedicalTicketsSearchPost$Plain$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-search-post-plain';
import { apiV10MedicalTicketsTicketIdGet$Json } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-get-json';
import { ApiV10MedicalTicketsTicketIdGet$Json$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-get-json';
import { apiV10MedicalTicketsTicketIdGet$Plain } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-get-plain';
import { ApiV10MedicalTicketsTicketIdGet$Plain$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-get-plain';
import { apiV10MedicalTicketsTicketIdPut$Json } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-put-json';
import { ApiV10MedicalTicketsTicketIdPut$Json$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-put-json';
import { apiV10MedicalTicketsTicketIdPut$Plain } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-put-plain';
import { ApiV10MedicalTicketsTicketIdPut$Plain$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-put-plain';
import { apiV10MedicalTicketsTicketIdRevisionsGet$Json } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-revisions-get-json';
import { ApiV10MedicalTicketsTicketIdRevisionsGet$Json$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-revisions-get-json';
import { apiV10MedicalTicketsTicketIdRevisionsGet$Plain } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-revisions-get-plain';
import { ApiV10MedicalTicketsTicketIdRevisionsGet$Plain$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-revisions-get-plain';
import { apiV10MedicalTicketsTicketIdStatusPatch$Json } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-status-patch-json';
import { ApiV10MedicalTicketsTicketIdStatusPatch$Json$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-status-patch-json';
import { apiV10MedicalTicketsTicketIdStatusPatch$Plain } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-status-patch-plain';
import { ApiV10MedicalTicketsTicketIdStatusPatch$Plain$Params } from '../fn/medical-ticket/api-v-10-medical-tickets-ticket-id-status-patch-plain';
import { TicketHistoryResponse } from '../models/ticket-history-response';
import { TicketQueryResponse } from '../models/ticket-query-response';
import { TicketResponse } from '../models/ticket-response';
import { TicketUpdateResponse } from '../models/ticket-update-response';

@Injectable({ providedIn: 'root' })
export class MedicalTicketService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV10MedicalTicketsPost()` */
  static readonly ApiV10MedicalTicketsPostPath = '/api/v1.0/medical-tickets';

  /**
   * Medical ticket creation api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsPost$Plain$Response(params: ApiV10MedicalTicketsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10MedicalTicketsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket creation api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsPost$Plain(params: ApiV10MedicalTicketsPost$Plain$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10MedicalTicketsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /**
   * Medical ticket creation api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsPost$Json$Response(params: ApiV10MedicalTicketsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10MedicalTicketsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket creation api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsPost$Json(params: ApiV10MedicalTicketsPost$Json$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10MedicalTicketsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /** Path part for operation `apiV10MedicalTicketsTicketIdGet()` */
  static readonly ApiV10MedicalTicketsTicketIdGetPath = '/api/v1.0/medical-tickets/{ticketId}';

  /**
   * Medical ticket get by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsTicketIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10MedicalTicketsTicketIdGet$Plain$Response(params: ApiV10MedicalTicketsTicketIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10MedicalTicketsTicketIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket get by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsTicketIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10MedicalTicketsTicketIdGet$Plain(params: ApiV10MedicalTicketsTicketIdGet$Plain$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10MedicalTicketsTicketIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /**
   * Medical ticket get by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsTicketIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10MedicalTicketsTicketIdGet$Json$Response(params: ApiV10MedicalTicketsTicketIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketResponse>> {
    return apiV10MedicalTicketsTicketIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket get by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsTicketIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10MedicalTicketsTicketIdGet$Json(params: ApiV10MedicalTicketsTicketIdGet$Json$Params, context?: HttpContext): Observable<TicketResponse> {
    return this.apiV10MedicalTicketsTicketIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketResponse>): TicketResponse => r.body)
    );
  }

  /** Path part for operation `apiV10MedicalTicketsTicketIdPut()` */
  static readonly ApiV10MedicalTicketsTicketIdPutPath = '/api/v1.0/medical-tickets/{ticketId}';

  /**
   * Medical ticket update by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsTicketIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsTicketIdPut$Plain$Response(params: ApiV10MedicalTicketsTicketIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10MedicalTicketsTicketIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket update by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsTicketIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsTicketIdPut$Plain(params: ApiV10MedicalTicketsTicketIdPut$Plain$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10MedicalTicketsTicketIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /**
   * Medical ticket update by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsTicketIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsTicketIdPut$Json$Response(params: ApiV10MedicalTicketsTicketIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10MedicalTicketsTicketIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket update by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsTicketIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsTicketIdPut$Json(params: ApiV10MedicalTicketsTicketIdPut$Json$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10MedicalTicketsTicketIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10MedicalTicketsSearchPost()` */
  static readonly ApiV10MedicalTicketsSearchPostPath = '/api/v1.0/medical-tickets/search';

  /**
   * Medical ticket search api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsSearchPost$Plain$Response(params: ApiV10MedicalTicketsSearchPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketQueryResponse>>> {
    return apiV10MedicalTicketsSearchPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket search api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsSearchPost$Plain(params: ApiV10MedicalTicketsSearchPost$Plain$Params, context?: HttpContext): Observable<Array<TicketQueryResponse>> {
    return this.apiV10MedicalTicketsSearchPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketQueryResponse>>): Array<TicketQueryResponse> => r.body)
    );
  }

  /**
   * Medical ticket search api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsSearchPost$Json$Response(params: ApiV10MedicalTicketsSearchPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketQueryResponse>>> {
    return apiV10MedicalTicketsSearchPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket search api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsSearchPost$Json(params: ApiV10MedicalTicketsSearchPost$Json$Params, context?: HttpContext): Observable<Array<TicketQueryResponse>> {
    return this.apiV10MedicalTicketsSearchPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketQueryResponse>>): Array<TicketQueryResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10MedicalTicketsExportPost()` */
  static readonly ApiV10MedicalTicketsExportPostPath = '/api/v1.0/medical-tickets/export';

  /**
   * export ticket.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsExportPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsExportPost$Plain$Response(params: ApiV10MedicalTicketsExportPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<File>> {
    return apiV10MedicalTicketsExportPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsExportPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsExportPost$Plain(params: ApiV10MedicalTicketsExportPost$Plain$Params, context?: HttpContext): Observable<File> {
    return this.apiV10MedicalTicketsExportPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<File>): File => r.body)
    );
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsExportPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsExportPost$Json$Response(params: ApiV10MedicalTicketsExportPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<File>> {
    return apiV10MedicalTicketsExportPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * export ticket.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsExportPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsExportPost$Json(params: ApiV10MedicalTicketsExportPost$Json$Params, context?: HttpContext): Observable<File> {
    return this.apiV10MedicalTicketsExportPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<File>): File => r.body)
    );
  }

  /** Path part for operation `apiV10MedicalTicketsTicketIdStatusPatch()` */
  static readonly ApiV10MedicalTicketsTicketIdStatusPatchPath = '/api/v1.0/medical-tickets/{ticketId}/status';

  /**
   * Medical ticket status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsTicketIdStatusPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsTicketIdStatusPatch$Plain$Response(params: ApiV10MedicalTicketsTicketIdStatusPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10MedicalTicketsTicketIdStatusPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsTicketIdStatusPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsTicketIdStatusPatch$Plain(params: ApiV10MedicalTicketsTicketIdStatusPatch$Plain$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10MedicalTicketsTicketIdStatusPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /**
   * Medical ticket status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsTicketIdStatusPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsTicketIdStatusPatch$Json$Response(params: ApiV10MedicalTicketsTicketIdStatusPatch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketUpdateResponse>> {
    return apiV10MedicalTicketsTicketIdStatusPatch$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsTicketIdStatusPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10MedicalTicketsTicketIdStatusPatch$Json(params: ApiV10MedicalTicketsTicketIdStatusPatch$Json$Params, context?: HttpContext): Observable<TicketUpdateResponse> {
    return this.apiV10MedicalTicketsTicketIdStatusPatch$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketUpdateResponse>): TicketUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10MedicalTicketsTicketIdRevisionsGet()` */
  static readonly ApiV10MedicalTicketsTicketIdRevisionsGetPath = '/api/v1.0/medical-tickets/{ticketId}/revisions';

  /**
   * Medical ticket history get by ticket id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsTicketIdRevisionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10MedicalTicketsTicketIdRevisionsGet$Plain$Response(params: ApiV10MedicalTicketsTicketIdRevisionsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketHistoryResponse>>> {
    return apiV10MedicalTicketsTicketIdRevisionsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket history get by ticket id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsTicketIdRevisionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10MedicalTicketsTicketIdRevisionsGet$Plain(params: ApiV10MedicalTicketsTicketIdRevisionsGet$Plain$Params, context?: HttpContext): Observable<Array<TicketHistoryResponse>> {
    return this.apiV10MedicalTicketsTicketIdRevisionsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketHistoryResponse>>): Array<TicketHistoryResponse> => r.body)
    );
  }

  /**
   * Medical ticket history get by ticket id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10MedicalTicketsTicketIdRevisionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10MedicalTicketsTicketIdRevisionsGet$Json$Response(params: ApiV10MedicalTicketsTicketIdRevisionsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketHistoryResponse>>> {
    return apiV10MedicalTicketsTicketIdRevisionsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Medical ticket history get by ticket id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10MedicalTicketsTicketIdRevisionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10MedicalTicketsTicketIdRevisionsGet$Json(params: ApiV10MedicalTicketsTicketIdRevisionsGet$Json$Params, context?: HttpContext): Observable<Array<TicketHistoryResponse>> {
    return this.apiV10MedicalTicketsTicketIdRevisionsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketHistoryResponse>>): Array<TicketHistoryResponse> => r.body)
    );
  }

}
