import {
  Component,
  DestroyRef,
  OnInit,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { ActiveUserCachedDetailsService } from '@business-level-utility/cache/active-user-cached-details.service';

import { AuthenticationService } from '@business-level-utility/services/authentication.service';
import { blockedItemsBaseUrl } from '@constants/route-urls/blocked-items.route-urls';
import { CdkPortalService } from '@utility/portal-service/cdk-portal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  private authService = inject(AuthenticationService);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  cdkPortalService = inject(CdkPortalService);
  _viewContainerRef = inject(ViewContainerRef);
  activeUserCachedDetailsService = inject(ActiveUserCachedDetailsService);

  constructor() {
    this.subscribeToStorageChanges();
    this.activeUserCachedDetailsService.setActiveUserDetails();
  }

  private subscribeToStorageChanges(): void {
    this.authService
      .getTokenStorageEventListener()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        if (!event.newValue || !this.authService.isUserAuthenticated()) {
          this.authService.logoutUser();
          return;
        }
        this.router.navigateByUrl(blockedItemsBaseUrl); //landing page
      });
  }

  ngOnInit(): void {}
}
