/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None
 *
 * 1 = Pending
 *
 * 2 = Delivered
 */
export enum EmailStatus {
  None = 0,
  Pending = 1,
  Delivered = 2
}
