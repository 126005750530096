import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FlaggedEnumService<T> {
  constructor() {}

  flagsToEnum(flags: number[], enumType: T): number {
    return flags.reduce((acc, flag) => acc | flag, 0);
  }

  enumToFlags(enumValue: number, enumType: T): number[] {
    const flags: number[] = [];
    for (const key in enumType) {
      if (isNaN(Number(key))) {
        const enumKeyValue = enumType[key as keyof T];
        if ((enumValue & (enumKeyValue as any)) === enumKeyValue) {
          if (enumKeyValue !== 0) {
            flags.push(enumKeyValue as any);
          }
        }
      }
    }
    return flags;
  }
}
