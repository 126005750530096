/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None
 *
 * 1 = Posting
 *
 * 2 = Deposit
 */
export enum AttachmentType {
  None = 0,
  Posting = 1,
  Deposit = 2
}
