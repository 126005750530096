/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None
 *
 * 1 = Eligibility
 *
 * 2 = Posting
 *
 * 4 = Medical
 */
export enum Permission {
  None = 0,
  Eligibility = 1,
  Posting = 2,
  Medical = 4
}
