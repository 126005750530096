/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV10LocationsGet$Json } from '../fn/locations/api-v-10-locations-get-json';
import { ApiV10LocationsGet$Json$Params } from '../fn/locations/api-v-10-locations-get-json';
import { apiV10LocationsGet$Plain } from '../fn/locations/api-v-10-locations-get-plain';
import { ApiV10LocationsGet$Plain$Params } from '../fn/locations/api-v-10-locations-get-plain';
import { apiV10LocationsLocationIdGet$Json } from '../fn/locations/api-v-10-locations-location-id-get-json';
import { ApiV10LocationsLocationIdGet$Json$Params } from '../fn/locations/api-v-10-locations-location-id-get-json';
import { apiV10LocationsLocationIdGet$Plain } from '../fn/locations/api-v-10-locations-location-id-get-plain';
import { ApiV10LocationsLocationIdGet$Plain$Params } from '../fn/locations/api-v-10-locations-location-id-get-plain';
import { apiV10LocationsLocationIdPut$Json } from '../fn/locations/api-v-10-locations-location-id-put-json';
import { ApiV10LocationsLocationIdPut$Json$Params } from '../fn/locations/api-v-10-locations-location-id-put-json';
import { apiV10LocationsLocationIdPut$Plain } from '../fn/locations/api-v-10-locations-location-id-put-plain';
import { ApiV10LocationsLocationIdPut$Plain$Params } from '../fn/locations/api-v-10-locations-location-id-put-plain';
import { apiV10LocationsPost$Json } from '../fn/locations/api-v-10-locations-post-json';
import { ApiV10LocationsPost$Json$Params } from '../fn/locations/api-v-10-locations-post-json';
import { apiV10LocationsPost$Plain } from '../fn/locations/api-v-10-locations-post-plain';
import { ApiV10LocationsPost$Plain$Params } from '../fn/locations/api-v-10-locations-post-plain';
import { apiV10LocationsSearchPost$Json } from '../fn/locations/api-v-10-locations-search-post-json';
import { ApiV10LocationsSearchPost$Json$Params } from '../fn/locations/api-v-10-locations-search-post-json';
import { apiV10LocationsSearchPost$Plain } from '../fn/locations/api-v-10-locations-search-post-plain';
import { ApiV10LocationsSearchPost$Plain$Params } from '../fn/locations/api-v-10-locations-search-post-plain';
import { LocationResponse } from '../models/location-response';
import { LocationUpdateResponse } from '../models/location-update-response';

@Injectable({ providedIn: 'root' })
export class LocationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV10LocationsGet()` */
  static readonly ApiV10LocationsGetPath = '/api/v1.0/locations';

  /**
   * get all location api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10LocationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10LocationsGet$Plain$Response(params?: ApiV10LocationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocationResponse>>> {
    return apiV10LocationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get all location api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10LocationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10LocationsGet$Plain(params?: ApiV10LocationsGet$Plain$Params, context?: HttpContext): Observable<Array<LocationResponse>> {
    return this.apiV10LocationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocationResponse>>): Array<LocationResponse> => r.body)
    );
  }

  /**
   * get all location api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10LocationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10LocationsGet$Json$Response(params?: ApiV10LocationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocationResponse>>> {
    return apiV10LocationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get all location api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10LocationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10LocationsGet$Json(params?: ApiV10LocationsGet$Json$Params, context?: HttpContext): Observable<Array<LocationResponse>> {
    return this.apiV10LocationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocationResponse>>): Array<LocationResponse> => r.body)
    );
  }

  /** Path part for operation `apiV10LocationsPost()` */
  static readonly ApiV10LocationsPostPath = '/api/v1.0/locations';

  /**
   * location creation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10LocationsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsPost$Plain$Response(params?: ApiV10LocationsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationResponse>> {
    return apiV10LocationsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * location creation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10LocationsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsPost$Plain(params?: ApiV10LocationsPost$Plain$Params, context?: HttpContext): Observable<LocationResponse> {
    return this.apiV10LocationsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocationResponse>): LocationResponse => r.body)
    );
  }

  /**
   * location creation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10LocationsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsPost$Json$Response(params?: ApiV10LocationsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationResponse>> {
    return apiV10LocationsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * location creation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10LocationsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsPost$Json(params?: ApiV10LocationsPost$Json$Params, context?: HttpContext): Observable<LocationResponse> {
    return this.apiV10LocationsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocationResponse>): LocationResponse => r.body)
    );
  }

  /** Path part for operation `apiV10LocationsLocationIdGet()` */
  static readonly ApiV10LocationsLocationIdGetPath = '/api/v1.0/locations/{locationId}';

  /**
   * get location by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10LocationsLocationIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10LocationsLocationIdGet$Plain$Response(params: ApiV10LocationsLocationIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationResponse>> {
    return apiV10LocationsLocationIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get location by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10LocationsLocationIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10LocationsLocationIdGet$Plain(params: ApiV10LocationsLocationIdGet$Plain$Params, context?: HttpContext): Observable<LocationResponse> {
    return this.apiV10LocationsLocationIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocationResponse>): LocationResponse => r.body)
    );
  }

  /**
   * get location by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10LocationsLocationIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10LocationsLocationIdGet$Json$Response(params: ApiV10LocationsLocationIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationResponse>> {
    return apiV10LocationsLocationIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get location by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10LocationsLocationIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV10LocationsLocationIdGet$Json(params: ApiV10LocationsLocationIdGet$Json$Params, context?: HttpContext): Observable<LocationResponse> {
    return this.apiV10LocationsLocationIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocationResponse>): LocationResponse => r.body)
    );
  }

  /** Path part for operation `apiV10LocationsLocationIdPut()` */
  static readonly ApiV10LocationsLocationIdPutPath = '/api/v1.0/locations/{locationId}';

  /**
   * location update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10LocationsLocationIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsLocationIdPut$Plain$Response(params: ApiV10LocationsLocationIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationUpdateResponse>> {
    return apiV10LocationsLocationIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * location update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10LocationsLocationIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsLocationIdPut$Plain(params: ApiV10LocationsLocationIdPut$Plain$Params, context?: HttpContext): Observable<LocationUpdateResponse> {
    return this.apiV10LocationsLocationIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocationUpdateResponse>): LocationUpdateResponse => r.body)
    );
  }

  /**
   * location update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10LocationsLocationIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsLocationIdPut$Json$Response(params: ApiV10LocationsLocationIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationUpdateResponse>> {
    return apiV10LocationsLocationIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * location update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10LocationsLocationIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsLocationIdPut$Json(params: ApiV10LocationsLocationIdPut$Json$Params, context?: HttpContext): Observable<LocationUpdateResponse> {
    return this.apiV10LocationsLocationIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocationUpdateResponse>): LocationUpdateResponse => r.body)
    );
  }

  /** Path part for operation `apiV10LocationsSearchPost()` */
  static readonly ApiV10LocationsSearchPostPath = '/api/v1.0/locations/search';

  /**
   * location search api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10LocationsSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsSearchPost$Plain$Response(params: ApiV10LocationsSearchPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocationResponse>>> {
    return apiV10LocationsSearchPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * location search api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10LocationsSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsSearchPost$Plain(params: ApiV10LocationsSearchPost$Plain$Params, context?: HttpContext): Observable<Array<LocationResponse>> {
    return this.apiV10LocationsSearchPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocationResponse>>): Array<LocationResponse> => r.body)
    );
  }

  /**
   * location search api.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV10LocationsSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsSearchPost$Json$Response(params: ApiV10LocationsSearchPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocationResponse>>> {
    return apiV10LocationsSearchPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * location search api.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV10LocationsSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV10LocationsSearchPost$Json(params: ApiV10LocationsSearchPost$Json$Params, context?: HttpContext): Observable<Array<LocationResponse>> {
    return this.apiV10LocationsSearchPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocationResponse>>): Array<LocationResponse> => r.body)
    );
  }

}
